export const tableOne = [
  { Type: 'Products' },
  {
    columns: [
      {
        id: 1,
        name: 'Product name',
      },
      {
        id: 2,
        name: 'Total cost',
      },
      {
        id: 3,
        name: 'Order Date',
      },
      {
        id: 4,
        name: 'Actions',
      },
    ],
  },
];
export const tableTwo = [
  { Type: 'Prescription' },
  {
    columns: [
      {
        id: 1,
        name: 'Prescription name',
      },
      {
        id: 2,
        name: 'Patient Name',
      },
      {
        id: 3,
        name: 'Patient ID/Number',
      },
      {
        id: 4,
        name: 'Refill Status',
      },
      {
        id: 5,
        name: 'Refill Date',
      },
      {
        id: 6,
        name: 'Actions',
      },
    ],
  },
];
export const tableThree = [
  { Type: 'Orders' },
  {
    columns: [
      {
        id: 1,
        name: 'Order Number',
      },
      {
        id: 2,
        name: 'Date Placed',
        icon: true,
      },
      {
        id: 3,
        name: 'Status',
        icon: true,
      },
      {
        id: 4,
        name: 'Total Cost',
        icon: true,
      },
      {
        id: 5,
        name: 'Item ordered',
      },
      {
        id: 6,
        name: 'Action',
      },
    ],
  },
];
export const tableFour = [
  { Type: 'Downloads' },
  {
    columns: [
      {
        id: 1,
        name: 'Download Name',
      },
      {
        id: 2,
        name: 'Description',
      },
      {
        id: 3,
        name: 'File Type',
      },
      {
        id: 4,
        name: 'File Size',
      },
      {
        id: 5,
        name: 'Date Added',
      },
      {
        id: 6,
        name: 'Action',
      },
    ],
  },
];
export const tableFive = [
  { Type: 'Cart' },
  {
    columns: [
      {
        id: 1,
        name: 'Item',
      },
      {
        id: 2,
        name: 'Dosage',
      },
      {
        id: 3,
        name: 'Size',
      },
      {
        id: 4,
        name: 'Color / Style',
      },
      {
        id: 5,
        name: 'Shipping',
      },
      {
        id: 6,
        name: 'Quantity',
      },
      {
        id: 7,
        name: 'Total Cost',
      },
      {
        id: 8,
        name: 'Action',
      },
    ],
  },
];
export const tableOneData = [
  {
    id: 1,
    img: 'https://chancellorswaypharmacy.com/wp-content/uploads/2018/12/Ideal-Protein-Ideal-Salt.jpg',
    product_name: 'Acetaminophen, Ibuprofen',
    price: '32.00',
    date: '2022-04-01',
  },
  {
    id: 2,
    img: 'https://chancellorswaypharmacy.com/wp-content/uploads/2018/12/Ideal-Protein-Ideal-Salt.jpg',
    product_name: 'Acetaminophen, Ibuprofen',
    price: '32.00',
    date: '2022-04-01',
  },
  {
    id: 3,
    img: 'https://chancellorswaypharmacy.com/wp-content/uploads/2018/12/Ideal-Protein-Ideal-Salt.jpgs',
    product_name: 'Acetaminophen, Ibuprofen',
    price: '32.00',
    date: '2022-04-01',
  },
];
export const tableTwoData = [
  {
    id: 1,
    name: 'Prescription 1',
    patient_name: 'Jhon Smith',
    patient_id: 'P123456',
    refill_status: 'Expired',
    refill_date: '2022-04-01',
  },
  {
    id: 2,
    name: 'Prescription 2',
    patient_name: 'Jhon Smith',
    patient_id: 'P123456',
    refill_status: 'Ready for Pickup',
    refill_date: '2022-04-01',
  },
  {
    id: 3,
    name: 'Prescription 2',
    patient_name: 'Jhon Smith',
    patient_id: 'P123456',
    refill_status: 'Completed',
    refill_date: '2022-04-01',
  },
];
export const tableThreeData = [
  {
    id: 1,
    orderNumber: 123455,
    date_created: 'May 1, 2023',
    status: 'Cancelled',
    total: 10,
    itemOrdered: 'Acetaminophen, Ibuprofen222',
  },
  {
    id: 2,
    orderNumber: 76763,
    date_created: 'May 3, 2023',
    status: 'Shipped',
    total: 20,
    itemOrdered: 'Acetaminophen, Ibuprofen11',
  },
  {
    id: 3,
    orderNumber: 79889,
    date_created: 'May 2, 2023',
    status: 'Shipped',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 4,
    orderNumber: 79889,
    date_created: 'May 2, 2023',
    status: 'Delivered',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 5,
    orderNumber: 79889,
    date_created: 'May 2, 2023',
    status: 'Cancelled',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 6,
    orderNumber: 79889,
    date_created: 'May 2, 2023',
    status: 'Delivered',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 7,
    orderNumber: 79889,
    date_created: 'MDecember 2, 2023',
    status: 'Cancelled',
    total: 18,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 8,
    orderNumber: 79889,
    date_created: 'June 2, 2023',
    status: 'Delivered',
    total: 22,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 9,
    orderNumber: 35435,
    date_created: 'Mrach 2, 2023',
    status: 'Delivered',
    total: 90,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 10,
    orderNumber: 434343,
    date_created: 'Aprill 2, 2023',
    status: 'Delivered',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
  {
    id: 11,
    orderNumber: 434343,
    date_created: 'Aprill 2, 2023',
    status: 'Delivered',
    total: 2,
    itemOrdered: 'Acetaminophen, Ibuprofen333',
  },
];
export const tableFourData = [
  {
    id: 1,
    records: 'Prescription Records',
    description:
      'A record of all prescriptions filled by the user at your pharmacy.',
    file_type: 'PDF',
    file_size: '1.2 MB',
    date: 'May 1, 2023',
  },
  {
    id: 2,
    records: 'Prescription Records',
    description:
      'A record of all prescriptions filled by the user at your pharmacy.',
    file_type: 'PDF',
    file_size: '1.2 MB',
    date: 'May 1, 2023',
  },
  {
    id: 3,
    records: 'Medication Guides',
    description:
      'A record of all prescriptions filled by the user at your pharmacy.',
    file_type: 'PDF',
    file_size: '1.5 MB',
    date: 'May 15, 2023',
  },
];
