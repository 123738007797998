import { createSlice } from '@reduxjs/toolkit';

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    products: [],
  },
  reducers: {
    addToWishlist: (state, action) => {
      const itemInCart = state.products.find(
        (item) =>
          item.product_id === action.payload.id &&
          item.variation_id === action.payload.variation_id
      );
      if (itemInCart) {
        const removeWItem = state.products.filter(
          (item) =>
            !(
              (item.product_id === action.payload.id) &
              (item.variation_id === action.payload.variation_id)
            )
        );
        state.products = removeWItem;
      } else {
        const { id, ...newPayload } = action.payload;
        state.products.push({
          ...newPayload,
          product_id: action.payload.id,
        });
      }
    },
    incrementWQuantity: (state, action) => {
      const item = state.products.find(
        (item) =>
          item.product_id === action.payload.product_id &&
          item.variation_id === action.payload.variation_id
      );
      item.quantity++;
    },
    decrementWQuantity: (state, action) => {
      const item = state.products.find(
        (item) =>
          item.product_id === action.payload.product_id &&
          item.variation_id === action.payload.variation_id
      );
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.products.filter(
        (item) =>
          !(
            (item.product_id === action.payload.product_id) &
            (item.variation_id === action.payload.variation_id)
          )
      );
      state.products = removeItem;
    },
  },
});
export const wishlistReducer = wishlistSlice.reducer;
export const {
  addToWishlist,
  incrementWQuantity,
  decrementWQuantity,
  removeItem,
} = wishlistSlice.actions;
