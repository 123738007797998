import React, { useEffect, useRef, useState } from 'react';
import './Footer.css';
import logo from '../../assets/newLogo.PNG';
import shippingIcon from '../../assets/shippingicon.png';
import facebook from '../../assets/facebook.svg';
// import twitter from '../../assets/twitter.svg';
// import insta from '../../assets/insta.svg';
// import linkedin from '../../assets/linkedin.svg';
import MailIcon from '../../assets/Mail-Icon.svg';
import squares from '../../assets/squares.svg';
import CompoundingPharmacy from '../../assets/CompoundingPharmacy.png';
import idealprotien from '../../assets/idealprotien.png';
import eco from '../../assets/eco.png';
import { Link } from 'react-router-dom';
import { openInNewTab } from '../../util/outsideAlert';
import { emailjsSend } from '../../util/emailjsConfig';
import { Alert, AlertTitle } from '@mui/material';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const form = useRef();

  useEffect(() => {
    const validate = () => {
      if (email) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validate();
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const res = await emailjsSend({ email: email }, 'template_msegg45');
    if (res) {
      setIsSent(true);
      form.current.value = '';
      setEmail('');
    }
  };

  return (
    <div className="Footer">
      <div className="Footer-Wrapper">
        <div className="upper-footer">
          <div className="boxes-wrapper">
            <div className="box">
              <div className="left">
                <img src={shippingIcon} alt="Shipping-icon" />
              </div>
              <div className="right">
                <p className="box-head">Free Home Delivery</p>
                <p className="box-desc">To your doorstep</p>
              </div>
            </div>
            <div className="box">
              <div className="left">
                <img src={CompoundingPharmacy} alt="Shipping-icon" />
              </div>
              <div className="right">
                <p className="box-head">Compounding Pharmacy</p>
                <p className="box-desc">Customized medications</p>
              </div>
            </div>
            <div className="box">
              <div className="left">
                <img src={idealprotien} alt="Shipping-icon" />
              </div>
              <div className="right">
                <p className="box-head">Ideal Protein Program</p>
                <p className="box-desc">Lose weight & keep healthy</p>
              </div>
            </div>
            <div className="box">
              <div className="left">
                <img src={eco} alt="Shipping-icon" />
              </div>
              <div className="right">
                <p className="box-head">Eco-friendly Pharmacy</p>
                <p className="box-desc">
                  Green. Healthy. Sustainable. Pharmacy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-footer">
          <div className="left-side">
            <div className="img-wrapper-footer">
              <img src={logo} alt="logo" />
            </div>
            <form onSubmit={handleSubmit}>
              <input
                className="newsletter"
                type="mail"
                placeholder="Type your email..."
                ref={form}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="image"
                src={MailIcon}
                alt="mail"
                className="submit"
                disabled={disabled}
              />
            </form>
            {isSent && (
              <Alert severity="success" style={{ marginTop: '10px' }}>
                <AlertTitle>Success</AlertTitle>
                Thank you for your subscription
              </Alert>
            )}
            <div className="social-icons">
              <a
                href="#"
                onClick={() =>
                  openInNewTab('https://www.facebook.com/cwpharmacy/')
                }
              >
                <img src={facebook} alt="facebook_icon" />
              </a>
              {/* <a href="www.facebook.com">
                <img src={insta} alt="insta_icon" />
              </a>
              <a href="www.facebook.com">
                <img src={twitter} alt="twitter_icon" />
              </a>
              <a href="www.facebook.com">
                <img src={linkedin} alt="linkedin_icon" />
              </a> */}
            </div>
          </div>
          <div className="right-side">
            <div className="services-menu">
              <p className="footer-header">Services</p>
              <ul>
                <li>
                  <Link to="/IdealProteinLandingPage">Ideal Protein</Link>
                </li>
                <li>
                  <Link to="/CFPatients">Compounding For Patients</Link>
                </li>
                <li>
                  <Link to="/CFPhysicians">Compounding For Physicians</Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      openInNewTab('https://bookmytest.ca/services/5198246445')
                    }
                  >
                    COVID-19 Test
                  </Link>
                </li>
                <li>
                  <Link to="/services?tab=1">Compression Stockings</Link>
                </li>
                <li>
                  <Link to="/services?tab=3">Free Flu Shots</Link>
                </li>
                <li>
                  <Link to="/services?tab=4">Medication Review</Link>
                </li>
                <li>
                  <Link to="/services?tab=2">Compliance Packs</Link>
                </li>
                <li>
                  <Link to="/minoraliments">Minor Aliments</Link>
                </li>
              </ul>
            </div>
            <div className="our-pharmacy-menu">
              <p className="footer-header">Our pharmacy</p>
              <ul>
                <li>
                  <Link to="/categories">Shop</Link>
                </li>
                <li>
                  <Link to="/About"> About Us </Link>
                </li>
                <li>
                  <Link to="/Contact"> Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="Prescription-menu">
              <p className="footer-header">Prescription</p>
              <ul>
                <li>
                  <Link to="/FillPrescriptionOnlinePage">
                    Fill Prescription Online
                  </Link>
                </li>
                <li>
                  <Link to="/PrescriptionRefillPage">Prescription Refill</Link>
                </li>
                <li>
                  <Link to="/prescriptiontransfer">Prescription Transfer</Link>
                </li>
              </ul>
            </div>
            <img src={squares} alt="bg-squares" className="bg-squares" />
          </div>
        </div>
        <div style={{ background: '#122146' }}>
          <div className="bottom-footer">
            <div className="copy-rights">
              <p>
                © Chancellors Way Pharmacy.{' '}
                <span>All rights reserved. - Website by</span>{' '}
                <Link
                  to="https://www.webelocity.io/"
                  style={{
                    color: '#fff',
                  }}
                >
                  Webelocity Inc.
                </Link>
              </p>
            </div>
            <div className="footer-menu">
              <ul>
                <li>
                  <Link to="/Policy">Return and Delivery Policy </Link>
                </li>
                <li>
                  <Link to="/Terms"> Terms </Link>
                </li>
                <li>
                  <Link to="/Privacy"> Privacy </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
