import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import Home from './pages/Home/Home';
// import Account from './pages/Account/Account';
// import Orders from './pages/Account/orders/orders';
// import Downloads from './pages/Account/Downloads/Downloads';
// import Address from './pages/Account/Addresss/Address';
// import SingleAddress from './pages/Account/SingleAddress/SingleAddress';
// import Payments from './pages/Account/Payments/Payments';
// import NewPayment from './pages/Account/NewPayment/NewPaymnet';
// import Settings from './pages/Account/Settings/Settings';
// import About from './pages/About/AboutUs';
// import Contact from './pages/Contact/ContactUs';
// import CFPhysicians from './pages/Compounding/CFPhysicians/CFPhysicians';
// import CFPatients from './pages/Compounding/CFPatients/CFPatients';
// import Covid from './pages/Covid/covid';
import Privacy from './pages/Privacy/privacy';
import Terms from './pages/Terms/terms';
import Policy from './pages/Policy/policy';
// import IdealProtien from './pages/IdealProtien/idealprotien';
// import Products from './pages/Products/Products';
import Cart from './pages/Cart/Cart';
// import SingleProduct from './pages/SingleProduct/SingleProduct';
// import Checkout from './pages/Checkout/Checkout';
import CheckoutComplete from './pages/CheckoutComplete/CheckoutComplete';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
// import Services from './pages/Services/Services';
// import ScheduleAppointment from './pages/Services/ScheduleAppointment/ScheduleAppointment';
// import MinorAlligments from './pages/MinorAlligments/MinorAlligments';
// import MinorAligmentAppointment from './pages/MinorAlligments/ScheduleAppointment/MinorAligmentAppointment';
// import IdealProteinLanding from './pages/IdealProteinLanding/IdealProteinLanding';
import { useSelector } from 'react-redux';
// import FillPrescriptionOnlinePage from './pages/presccription/FillPrescriptionOnlinePage';
// import PrescriptionRefillPage from './pages/presccription/PrescriptionRefillPage';
// import Prescriptiontransfer from './pages/presccription/prescriptiontransfer';
// import Maintain from './pages/UnderMaintainance';
// import UnderMaintainance from './pages/UnderMaintainance';
// import IdealProtienAdmin from './pages/IdealProtien/idealProtienAdmin/IdealProtienAdmin';
// import IdealProtienAdminPrev from './pages/IdealProtien/idealprotienAdminPrev';
// import SignInAdmin from './pages/SignIn/signInAdmin';
import { CircularProgress } from '@mui/material';
const Home = React.lazy(() => import('./pages/Home/Home'));
const Products = React.lazy(() => import('./pages/Products/Products'));
const Services = React.lazy(() => import('./pages/Services/Services'));
const IdealProteinLanding = React.lazy(() =>
  import('./pages/IdealProteinLanding/IdealProteinLanding')
);
const MinorAlligments = React.lazy(() =>
  import('./pages/MinorAlligments/MinorAlligments')
);
const IdealProtien = React.lazy(() =>
  import('./pages/IdealProtien/idealprotien')
);
const CFPhysicians = React.lazy(() =>
  import('./pages/Compounding/CFPhysicians/CFPhysicians')
);
const CFPatients = React.lazy(() =>
  import('./pages/Compounding/CFPatients/CFPatients')
);
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'));
const SingleProduct = React.lazy(() =>
  import('./pages/SingleProduct/SingleProduct')
);
const Contact = React.lazy(() => import('./pages/Contact/ContactUs'));

const FillPrescriptionOnlinePage = React.lazy(() =>
  import('./pages/presccription/FillPrescriptionOnlinePage')
);
const PrescriptionRefillPage = React.lazy(() =>
  import('./pages/presccription/PrescriptionRefillPage')
);
const Prescriptiontransfer = React.lazy(() =>
  import('./pages/presccription/prescriptiontransfer')
);
const About = React.lazy(() => import('./pages/About/AboutUs'));
function App() {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <Home />
                </Suspense>
              }
            />
            {/* <Route path="Maintainance" element={<Maintain />} /> */}

            {/* {user && user.email !== 'info@cwpharm.ca' && (
              <Route path="my-account">
                <Route path="dashboard" element={<Account />} />
                <Route path="orders" element={<Orders />} />
                <Route path="downloads" element={<Downloads />} />
                <Route path="address" element={<Address />} />
                <Route
                  path="address/:addressType"
                  element={<SingleAddress />}
                />
                <Route path="payment-method" element={<Payments />} />
                <Route path="payment-method/addNew" element={<NewPayment />} />
                <Route path="settings" element={<Settings />} />
              </Route>
            )} */}
            <Route
              path="About"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <About />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="Contact"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Contact />
              </Suspense>
            }
          />

          <Route
            path="CFPatients"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <CFPatients />
              </Suspense>
            }
          />
          <Route
            path="FillPrescriptionOnlinePage"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                {' '}
                <FillPrescriptionOnlinePage />
              </Suspense>
            }
          />
          <Route
            path="PrescriptionRefillPage"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <PrescriptionRefillPage />
              </Suspense>
            }
          />
          <Route
            path="prescriptiontransfer"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Prescriptiontransfer />
              </Suspense>
            }
          />

          <Route
            path="CFPhysicians"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <CFPhysicians />
              </Suspense>
            }
          />

          <Route
            path="categories/:catId"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Products />{' '}
              </Suspense>
            }
          />
          <Route
            path={'categories'}
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Products />{' '}
              </Suspense>
            }
          />

          <Route
            path="Contact"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Contact />
              </Suspense>
            }
          />
          {/* <Route path="Covid" element={<Covid />} /> */}
          <Route path="Privacy" element={<Privacy />} />
          <Route path="Terms" element={<Terms />} />
          <Route path="Policy" element={<Policy />} />
          <Route
            path="IdealProteinLandingPage"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <IdealProteinLanding />
              </Suspense>
            }
          />
          {/* <Route path="admin/auth/login" element={<SignInAdmin />} />
          {user && user.email === 'info@cwpharm.ca' && (
            <Route path="admin/ideal" element={<IdealProtienAdmin />} />
          )}
          {user && user.email === 'info@cwpharm.ca' && (
            <Route
              path="admin/ideal/:formId"
              element={<IdealProtienAdminPrev />}
            />
          )} */}
          <Route
            path="IdealProtien"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <IdealProtien />
              </Suspense>
            }
          />
          <Route
            path="product/:productId"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <SingleProduct />
              </Suspense>
            }
          />
          <Route path="cart" element={<Cart />} />
          <Route
            path="checkout"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              >
                <Checkout />
              </Suspense>
            }
          />
          <Route path="/order-complete" element={<CheckoutComplete />} />
          <Route path="auth">
            <Route
              path="register"
              element={
                user ? <Navigate to={'/my-account/dashboard'} /> : <SignUp />
              }
            />
            <Route
              path="login"
              element={
                user ? <Navigate to={'/my-account/dashboard'} /> : <SignIn />
              }
            />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="services">
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <Services />
                </Suspense>
              }
            />
            {/* <Route
              path="schedule-appointment"
              element={<ScheduleAppointment />}
            /> */}
          </Route>
          <Route path="minoraliments">
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  }
                >
                  <MinorAlligments />
                </Suspense>
              }
            />
            {/* <Route
              path="book-appointment"
              element={<MinorAligmentAppointment />}
            /> */}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {/* <Routes>
          <Route path="/" element={<UnderMaintainance />} />
        </Routes> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
