import React, { useState } from 'react';
import './SignIn.scss';
import mainImage from '../../assets/image 44.svg';
import logo from '../../assets/Black.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputBase,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BsChevronLeft } from 'react-icons/bs';
import { AuthRequest } from '../../util/ApiRequests';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure, loginStart, loginSuccess } from '../../Redux/userSlice';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState(null);
  const { isFetching, error } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleChange = (e) => {
    let name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  // useEffect(() => {
  //   if (data.username && data.password) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [data]);

  const handleLogin = async () => {
    try {
      dispatch(loginStart());
      const res = await AuthRequest.post('/token', data);
      if (res.status === 200) {
        const userData = res.data;
        localStorage.setItem('Authorization', userData.data.token);
        dispatch(loginSuccess(userData.data));
        navigate('/my-account/dashboard');
      }
    } catch (err) {
      dispatch(loginFailure());
      setErrMsg(err.response.data.message);
    }
  };

  return (
    <div className="auth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign in </title>
        <link rel="canonical" href={`${domain}/auth/login`} />
      </Helmet>
      <div className="wrapper">
        <div className="left-div">
          <Link to="/">
            <img src={logo} alt="logo" className="logo-img" />
          </Link>
          <img src={mainImage} alt="main" className="bottom-img" />
        </div>
        <div className="right-div">
          <Link to="/" className="home-link">
            <BsChevronLeft />
            back to home page
          </Link>
          <div className="form-div">
            <div className="title-div">
              <p className="title">Login </p>
              <h1 className="slogan">
                By creating an account you will be able to join our pharmacy!
              </h1>
            </div>
            <div className="inputs">
              <div className="input-div">
                <label>Email address</label>
                <input
                  disabled={isFetching}
                  name="username"
                  type="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <div className="input-div">
                <div className="password-labels">
                  <label>Your password</label>
                  <Link to="/auth/forgot-password">Forgot password?</Link>
                </div>

                <InputBase
                  disabled={isFetching}
                  className="mui-regiter-paasword"
                  onChange={handleChange}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              {error && (
                <Alert style={{ width: '100%' }} severity="error">
                  {errMsg}
                </Alert>
              )}
              <div className="submit-div">
                <button
                  className="submit-bttn"
                  disabled={disabled || isFetching}
                  onClick={handleLogin}
                >
                  {!isFetching ? 'Login (soon)' : <CircularProgress />}
                </button>
                <span>
                  Already have an account?
                  <Link to="/auth/register">Sign up</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
