import React, { useEffect } from 'react';
import './Table.scss';
import { BiSortAlt2 } from 'react-icons/bi';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import {
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';

const TableWrapper = ({
  data,
  columns,
  maxItemsToShow,
  search,
  searchType,
  handleDecrement,
  handleIncrement,
  handleRemove,
  setOrderNumber,
  disabled,
  cut,
}) => {
  const [sortedField, setSortedField] = useState(null);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const path = window.location.pathname;
  const tabName = path.split('/').pop();

  const getDataName = (columName) => {
    setCount(count + 1);
    if (columName === 'Status') {
      // assign with the same value for status as inside wooCommerce DB
      return 'status';
    }
    if (columName === 'Total Cost') {
      // assign with the same value for total as inside wooCommerce DB
      return 'total';
    }
    if (columName === 'Date Placed') {
      // assign with the same value for data as inside wooCommerce DB
      return 'date_created';
    }
  };

  const handleChange = (e, page) => {
    setCurrentPage(parseInt(page));
  };

  useEffect(() => {
    const getTotalPages = () => {
      const pagesCount = Math.ceil(
        data.length / maxItemsToShow
      );
      setPages(pagesCount);
    };
    if (data) {
      getTotalPages();
    }
  }, [data, maxItemsToShow]);

  return (
    <div className="table-wrapper">
      <table>
        <thead className={`${columns[0].Type}`}>
          <tr>
            {columns[1].columns.map((column) => (
              <th
                key={column.id}
                onClick={() =>
                  column.icon &&
                  setSortedField(getDataName(column.name))
                }
              >
                {column.name}
                {column.icon && (
                  <BiSortAlt2
                    style={{
                      fontSize: '14px',
                      paddingLeft: '3px',
                    }}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              (currentPage - 1) * maxItemsToShow,
              maxItemsToShow
                ? maxItemsToShow * pages
                : !cut
                ? 3
                : data.length
            )
            .slice()
            .sort((a, b) => {
              if (sortedField !== null && count > 0) {
                // check if the sort value is a number and if even or odd click will sort in the inverse order
                if (count % 2 === 0) {
                  return a[sortedField] < b[sortedField]
                    ? -1
                    : 1;
                } else {
                  return a[sortedField] < b[sortedField]
                    ? 1
                    : -1;
                }
              } else {
                return 0;
              }
            })
            .filter((item) => {
              if (search > 0) {
                const orderNumber =
                  item[searchType].toString();
                return orderNumber.includes(search);
              } else {
                return item;
              }
            })
            .map((item, i) =>
              item.img && columns[0].Type !== 'Cart' ? (
                <tr key={item.id}>
                  <td className="product-identity">
                    <img
                      src="https://chancellorswaypharmacy.com/wp-content/uploads/2018/12/Ideal-Protein-Ideal-Salt.jpg"
                      alt="product"
                    />
                    <div className="abandoned-pro-title">
                      <p>{item.product_name}</p>
                      {/* <span>100% shipping discount</span> */}
                    </div>
                  </td>
                  <td>${item.price}</td>
                  <td>{item.date}</td>
                  <td>
                    <button className="abandoned-addtocart">
                      Add To Cart
                    </button>
                  </td>
                </tr>
              ) : columns[0].Type === 'Prescription' ? (
                <tr
                  style={{ height: '43px' }}
                  key={item.id}
                >
                  <td>{item.name}</td>
                  <td>{item.patient_name}</td>
                  <td>{item.patient_id}</td>
                  <td>
                    <p
                      className={`status ${
                        item.refill_status ===
                        'Ready for Pickup'
                          ? 'Ready'
                          : item.refill_status
                      }`}
                    >
                      {item.refill_status}
                    </p>
                  </td>
                  <td>{item.refill_date}</td>
                  <td>
                    <button className="abandoned-addtocart">
                      Refill Now
                    </button>
                  </td>
                </tr>
              ) : item.date_created &&
                columns[0].Type !== 'Cart' ? (
                <tr
                  style={{ height: '43px' }}
                  key={item.id}
                >
                  <td>{item.orderNumber}</td>
                  <td>{item.date_created}</td>
                  <td>
                    <p
                      className={`status ${
                        item.status === 'Ready for Pickup'
                          ? 'Ready'
                          : item.status
                      }`}
                    >
                      {item.status}
                    </p>
                  </td>
                  <td>${item.total}</td>
                  <td>{item.itemOrdered}</td>
                  <td>
                    <button className="abandoned-addtocart">
                      Reorder
                    </button>
                    <button
                      className="abandoned-addtocart"
                      onClick={() =>
                        setOrderNumber(item.orderNumber)
                      }
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ) : columns[0].Type !== 'Cart' ? (
                <tr
                  style={{
                    height: '67px',
                    fontSize: '14px',
                  }}
                  key={item.id}
                >
                  <td>{item.records}</td>
                  <td>
                    <p
                      style={{
                        maxWidth: '314px',
                        lineHeight: '21px',
                      }}
                    >
                      {item.description}
                    </p>
                  </td>
                  <td>{item.file_type}</td>
                  <td>{item.file_size}</td>
                  <td>{item.date}</td>
                  <td>
                    <button className="abandoned-addtocart">
                      Download
                    </button>
                  </td>
                </tr>
              ) : (
                <tr
                  key={i}
                  className={`${columns[0].Type}-row`}
                >
                  <td className="product-identity">
                    <img
                      src={
                        item.images[0]
                          ? item.images[0].src
                          : 'https://woocommerce-893893-3943994.cloudwaysapps.com/wp-content/plugins/woocommerce/assets/images/placeholder.png'
                      }
                      alt="product"
                    />
                    <div className="abandoned-pro-title">
                      <p>{item.name}</p>
                      {/* <span>100% shipping discount</span> */}
                    </div>
                  </td>
                  <td id="ProDescription">
                    <span
                      style={{
                        maxWidth: '160px',
                        display: 'block',
                      }}
                    >
                      {!item.dosage ? '-' : item.dosage}
                    </span>
                  </td>
                  <td id="ProSize">
                    {item.size ? (
                      <div>
                        <span>Size:</span>
                        <span className="attr">
                          {' '}
                          {item.size}
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td id="ProColor">
                    {item.color ? (
                      <div>
                        <span>Color:</span>
                        <span className="attr">
                          {' '}
                          {item.color}
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td id="ProShipping">
                    <div>
                      <span>Shipping:</span>
                      <span className="ship">
                        {' '}
                        Delivery
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="quantity-box">
                      <button
                        className="decrement-button"
                        onClick={() =>
                          handleDecrement(item)
                        }
                        disabled={
                          item.quantity === 1 || disabled
                        }
                      >
                        <AiOutlineMinus />
                      </button>
                      <span className="quantity">
                        {item.quantity}
                      </span>
                      <button
                        disabled={disabled}
                        className="increment-button"
                        onClick={() =>
                          handleIncrement(item)
                        }
                      >
                        <AiOutlinePlus />
                      </button>
                    </div>
                  </td>
                  <td id="ProPrices">
                    {item.on_sale && (
                      <span className="old">
                        $
                        {(
                          item.price * item.quantity
                        ).toFixed(2)}
                      </span>
                    )}

                    <span className="new">
                      $
                      {Math.round(
                        (item.on_sale
                          ? item.sale_price
                          : item.price) *
                          item.quantity *
                          100
                      ) / 100}
                    </span>
                  </td>
                  {!disabled && (
                    <td>
                      <button
                        className="remove-cartItem"
                        onClick={() => handleRemove(item)}
                      >
                        Remove
                      </button>
                    </td>
                  )}
                </tr>
              )
            )}
        </tbody>
      </table>
      {pages > 1 && tabName === 'orders' && (
        <div className="pagination-wrapper">
          <Pagination
            count={pages}
            defaultPage={1}
            siblingCount={0}
            onChange={(e, page) => handleChange(e, page)}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  next: (props) => (
                    <span
                      {...props}
                      style={{
                        width: '87px',
                        fontSize: '14px',
                        color: '#122146',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                      }}
                    >
                      Next
                      <GrFormNext />
                    </span>
                  ),
                  previous: (props) => (
                    <span
                      {...props}
                      style={{
                        width: '115px',
                        fontSize: '14px',
                        color: '#122146',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                      }}
                    >
                      <GrFormPrevious />
                      Previous
                    </span>
                  ),
                }}
                {...item}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default TableWrapper;
