import React from 'react';
import NavBar from '../../components/Navbar/Navbar';
import Banner from '../../components/banner/Banner';
import Footer from '../../components/Footer/Footer';
import '../Privacy/privacy.css';
import Policyimg from '../../assets/Policy.png';
import Drowpdown from '../../components/Navbar/Dropdown';
import { Slide, Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const Policy = () => {
  return (
    <div className="Privacy-Wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Return Policy </title>
        <link rel="canonical" href={`${domain}/Policy`} />
      </Helmet>
      <Banner />
      <NavBar />
      <Drowpdown />

      <div className="Privacy_banner_section_wrapper">
        <div className="Privacy_banner_section">
          <Fade triggerOnce>
            <img src={Policyimg} alt="Privacy" />
          </Fade>
        </div>
      </div>
      <div style={{ background: '#F8FAFC' }}>
        <div className="Privacy_text_wrapper">
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h2>Return Policy</h2>
              <h3>Returns</h3>
              <p>
                We do not accept returns or exchanges unless
                the item you purchased is defective. If you
                receive a defective item, please contact us
                at 519-824-6445 with details of the product
                and the defect.
              </p>
            </Slide>
          </div>
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h3>
                You can send the item you consider defective
                to:
              </h3>
              <p id="blue_before">
                Chancellors Way Pharmacy
                <br /> <br />
                <span>
                  105 – 175 Chancellors Way <br />
                  Guelph, Ontario, N1G0E9
                </span>
              </p>
              <p>
                Upon receipt of the returned product, we
                will fully examine it and notify you via
                e-mail, within a reasonable period of time,
                whether you are entitled to a refund or a
                replacement as a result of the defect. If
                you are entitled to a replacement or refund,
                we will replace the product or refund the
                purchase price using the original method of
                payment.
              </p>
            </Slide>
          </div>
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h2>Shipping</h2>
              <h3>
                To return the item you purchased, please
                mail it to:
              </h3>
              <p>
                Chancellors Way Pharmacy
                <br />
                Refunds do not include any shipping and
                handling charges shown on the packaging slip
                or invoice. Shipping charges for all returns
                must be prepaid and insured by you. You are
                responsible for any loss or damage during
                shipment. We do not guarantee that we will
                receive your returned item. Shipping and
                handling charges are not refundable. Any
                amounts refunded will not include the cost
                of shipping.
              </p>
            </Slide>
          </div>
          <hr className="solid" />
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h2>Delivery Policy</h2>
              <h3>General Information</h3>
              <p>
                All orders are subject to product
                availability. If an item is not in stock at
                the time you place your order, we will
                notify you and refund you the total amount
                of your order using the original method of
                payment. 
              </p>
              <h3>Delivery Location</h3>
              <p>
                There are two groups of products available
                on our website: for registered customers and
                non-registered customers.  Items offered on
                our website to non-registered customers are
                available for delivery to addresses in
                Canada and the USA up to a maximum of a
                14-day supply. Items offered on our website
                to registered customers are only available
                for delivery to addresses in Canada and the
                USA in any amount they would like. To find
                out how to become a registered customer,
                please contact us at (519) 824 6445.
              </p>
              <h3>Delivery Time</h3>
              <p>
                An estimated delivery time will be provided
                to you once your order is placed. Delivery
                times are estimates and commence from the
                date of shipping rather than the date of
                order. Delivery times are to be used as a
                guide only and are subject to the acceptance
                and approval of your order.
                <br />
                Unless there are exceptional circumstances,
                we make every effort to fulfill your order
                within 1 business day of the date of your
                order. Business days mean Monday to Friday,
                except holidays.
              </p>
              <h3>
                Please note we do not ship on Saturdays,
                Sundays or holidays.
              </h3>
              <p>
                The date of delivery may vary due to carrier
                shipping practices, delivery location,
                method of delivery, and the items ordered.
                Products may also be delivered in separate
                shipments.
              </p>
            </Slide>
          </div>
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h2>Delivery Instructions</h2>
              <p>
                Once an order is successfully processed, you
                will receive a confirmation email.
              </p>
            </Slide>
          </div>
          <div className="text_block">
            <Slide
              duration={400}
              direction="left"
              damping={0.5}
              triggerOnce
              cascade
            >
              <h2>Shipping Costs</h2>
              <p>
                Shipping costs are based on the order value
                before taxes. If the total value of the
                order is less or equal to 200 CAD, shipping
                charges are 9.99 CAD + HST in Canada. For a
                total order value greater than 200 CAD
                before taxes, shipping is free in Ontario.
                For orders to the USA, a $39.99 shipping fee
                will apply. To find out how much your order
                will cost, simply add the items you would
                like to purchase to your cart and proceed to
                the checkout page. Once at the checkout
                screen, shipping charges will be displayed.
                This is an estimate only and is subject to
                change at any time without notice. If you
                would like an estimate prior to paying,
                please call 519-824-6445 or email
                info@cwpharm.ca
              </p>
              <h3>
                Ontario HST sales tax is charged to all
                items subject to taxes.
              </h3>
              <h3>Damaged Items in Transport</h3>
              <p>
                If there is any damage to the packaging on
                delivery, contact us immediately at (519)
                824 6445.
              </p>
              <h3>Questions</h3>
              <p>
                If you have any questions about the delivery
                and shipment or your order, please contact
                us at (519) 824 6445.
              </p>
            </Slide>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
