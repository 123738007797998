import React from 'react';
import './MiniCart.scss';
import cartIcon from '../../assets/cart.svg';
import {
  AiOutlineClose,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  decrementQuantity,
  incrementQuantity,
} from '../../Redux/cartSlice';
import { useEffect, useState } from 'react';

const MiniCart = ({ setShow, show }) => {
  const cart = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [totalCartQ, setTotalcartQ] = useState(0);
  const [totalCartP, setTotalcartP] = useState(0);

  const handleDecrement = (item) => {
    dispatch(decrementQuantity(item));
  };
  const handleIncrement = (item) => {
    dispatch(incrementQuantity(item));
  };

  useEffect(() => {
    const getTotalCart = () => {
      if (cart?.length) {
        const totalQuantity = cart.reduce(
          (prev, current) => prev + current.quantity,
          0
        );
        const totalAmount = cart.reduce(
          (prev, current) =>
            prev +
            current.quantity *
              (current.on_sale
                ? current.sale_price
                : current.price),
          0
        );
        setTotalcartQ(totalQuantity);
        setTotalcartP(Math.round(totalAmount * 100) / 100);
      }
    };
    getTotalCart();
  }, [cart]);

  return (
    <div className={`MiniCart ${show && 'show'}`}>
      <div className={`mini-cart-container ${show && 'show'}`}>
        <div className="top">
          <div className="left">
            <img src={cartIcon} alt="cart-icon" />
            <span className="cart-title">Cart</span>
            <Link to="/cart">View Cart</Link>
          </div>
          <div className="close" onClick={() => setShow(false)}>
            <AiOutlineClose
              style={{
                color: '#5F7586',
                fontSize: '24px',
              }}
            />
          </div>
        </div>
        <div className="ItemsWrapper">
          {cart?.map((cartItem, i) => (
            <div className="item-box" key={i}>
              <div className="img-box">
                <img
                  src={
                    cartItem.images[0]
                      ? cartItem.images[0].src
                      : 'https://woocommerce-893893-3943994.cloudwaysapps.com/wp-content/plugins/woocommerce/assets/images/placeholder.png'
                  }
                  alt="product-img"
                />
              </div>
              <div className="right-div">
                <div className="top-item">
                  <span className="topItemstext">
                    {cartItem.categories[0]?.name}
                  </span>
                  <span>{cartItem.name}</span>
                </div>
                <div className="description-box">
                  <div className="quantity-box">
                    <button
                      className="decrement-button"
                      onClick={() =>
                        handleDecrement(cartItem)
                      }
                      disabled={cartItem.quantity === 1}
                    >
                      <AiOutlineMinus />
                    </button>
                    <span className="quantity">
                      {cartItem.quantity}
                    </span>
                    <button
                      className="increment-button"
                      onClick={() =>
                        handleIncrement(cartItem)
                      }
                    >
                      <AiOutlinePlus />
                    </button>
                  </div>
                  <span className="price-text">
                    $
                    {Math.round(
                      (cartItem.on_sale
                        ? cartItem.sale_price
                        : cartItem.price) *
                        cartItem.quantity *
                        100
                    ) / 100}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-minicart">
          <div className="totalcart-div">
            <span className="total-items">
              {totalCartQ} item(s)
            </span>
            <span className="total-price">
              ${totalCartP}
            </span>
          </div>
          <Link 
            to={totalCartQ > 0 ? '/checkout' : '#'} 
            className={`buy-button ${totalCartQ === 0 ? 'disabled' : ''}`}
            style={{ pointerEvents: totalCartQ === 0 ? 'none' : 'auto' }}
          >
            Buy now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
