import React, { useState } from 'react';
import './SignUp.scss';
import mainImage from '../../assets/image 44.svg';
import logo from '../../assets/Black.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { BsChevronLeft } from 'react-icons/bs';
import { useEffect } from 'react';
import { publicRequest } from '../../util/ApiRequests';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({});
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    let name = e.target.name;
    const value = e.target.value;
    if (name !== 'privacyPolicy') {
      setData({ ...data, [name]: value });
    } else {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    if (
      data.first_name &&
      data.last_name &&
      data.password &&
      data.email &&
      checked
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [data, checked]);

  const handleRegister = async () => {
    try {
      setIsLoading(true);
      await publicRequest('post', '/customers', data);
      setIsLoading(false);
      navigate('/auth/login');
    } catch (err) {
      setError(err.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="auth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up </title>
        <link
          rel="canonical"
          href={`${domain}/auth/register`}
        />
      </Helmet>
      <div className="wrapper">
        <div className="left-div">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="logo-img"
            />
          </Link>
          <img
            src={mainImage}
            alt="main"
            className="bottom-img"
          />
        </div>
        <div className="right-div">
          <Link to="/" className="home-link">
            <BsChevronLeft />
            back to home page
          </Link>
          <div className="form-div">
            <div className="title-div">
              <p className="title">Sign up</p>
              <h1 className="slogan">
                By creating an account you will be able to
                join our pharmacy!
              </h1>
            </div>
            <div className="inputs">
              <div className="input-div short">
                <label>Your name</label>
                <input
                  disabled={isLoading}
                  type="text"
                  name="first_name"
                  placeholder="Enter your first name"
                  onChange={handleChange}
                />
              </div>
              <div className="input-div short">
                <label>Your last name</label>
                <input
                  disabled={isLoading}
                  type="text"
                  name="last_name"
                  placeholder="Enter your last name"
                  onChange={handleChange}
                />
              </div>
              <div className="input-div">
                <label>Email address</label>
                <input
                  disabled={isLoading}
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <div className="input-div">
                <label>Your password</label>
                <InputBase
                  disabled={isLoading}
                  className="mui-regiter-paasword"
                  onChange={handleChange}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((show) => !show)
                        }
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isLoading}
                      name="privacyPolicy"
                      onChange={handleChange}
                    />
                  }
                  label="I understood the terms & policy."
                />
              </div>
              {error && (
                <Alert
                  style={{ width: '100%' }}
                  severity="error"
                >
                  {error}
                </Alert>
              )}
              <div className="submit-div">
                <button
                  className="submit-bttn"
                  disabled={disabled || isLoading}
                  onClick={handleRegister}
                >
                  {!isLoading ? (
                    'Sign up'
                  ) : (
                    <CircularProgress />
                  )}
                </button>
                <span>
                  Already have an account?
                  <Link to="/auth/login">Log in</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
