import React, { useRef, useState, useEffect } from "react";
import "./WhishListPopup.scss";
import { BsDot } from "react-icons/bs";
import { AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementWQuantity,
  incrementWQuantity,
  removeItem,
} from "../../Redux/wishListSlice";
import { addToCart } from "../../Redux/cartSlice";
import { MdRemoveShoppingCart } from "react-icons/md";

const WhishListPopup = ({ setShowWish }) => {
  const whislist = useRef(null);
  const [totalCartQ, setTotalcartQ] = useState(0);
  const [totalCartP, setTotalcartP] = useState(0);
  const wishlist = useSelector((state) => state.wishlist.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleIncrement = (product) => {
    dispatch(incrementWQuantity(product));
  };

  const handleDecrement = (product) => {
    dispatch(decrementWQuantity(product));
  };

  const handleRemove = (product) => {
    dispatch(removeItem(product));
  };

  const handleAddToCart = (e, data) => {
    e.stopPropagation();
    if (data.type !== "variable") {
      dispatch(addToCart(data));
    } else {
      navigate(`/product/${data.product_id}`);
    }
  };

  useEffect(() => {
    const getTotalCart = () => {
      if (wishlist.length) {
        const totalQuantity = wishlist.reduce(
          (prev, current) => prev + current.quantity,
          0
        );
        const totalAmount = wishlist.reduce(
          (prev, current) =>
            prev +
            current.quantity *
              (current.on_sale ? current.sale_price : current.price),
          0
        );
        setTotalcartQ(totalQuantity);
        setTotalcartP(Math.round(totalAmount * 100) / 100);
      } else {
        setTotalcartP(0);
        setTotalcartQ(0);
      }
    };
    getTotalCart();
  }, [wishlist]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowWish(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(whislist);

  return (
    <div className="whishlist">
      <div className="wrapper" ref={whislist}>
        <div className="top-div">
          <div style={{ display: "flex" }} className="top-div-right-wrap">
            <p>Wishlist</p>
            <span className="badge">
              <span className="count">{totalCartQ} item(s)</span>
              <span className="total">
                <BsDot style={{ fontSize: "22px" }} />$ {totalCartP}
              </span>
            </span>
          </div>
          <AiOutlineClose
            className="Closei"
            style={{
              color: "#1D252C1A",
              cursor: "pointer",
            }}
            onClick={() => setShowWish(false)}
          />
        </div>
        <div className="scrollable-wrapper">
          {wishlist.map((item, i) => (
            <div className={`product-box-wish ${i % 2 !== 0 && "blue"}`} key={i}>
              <div className="left">
                <div className="img-div">
                  <img
                    src={
                      item.images.length > 0
                        ? item.images[0].src
                        : "https://woocommerce-893893-3943994.cloudwaysapps.com/wp-content/plugins/woocommerce/assets/images/placeholder.png"
                    }
                    alt="img"
                  />
                </div>
                <div className="item-desc">
                  <div>
                    <p className="category">{item.categories[0]?.name}</p>
                    <p className="name">{item.name}</p>
                  </div>

                  <div className="item-q">
                    <div className="counter-div">
                      {item.quantity === 1 ? (
                        <RiDeleteBin6Line
                          className="icon"
                          onClick={() => handleRemove(item)}
                        />
                      ) : (
                        <AiOutlineMinus
                          className="icon"
                          onClick={() => handleDecrement(item)}
                        />
                      )}

                      <span className="count">{item.quantity}</span>
                      <AiOutlinePlus
                        className="icon"
                        onClick={() => handleIncrement(item)}
                      />
                    </div>
                    <p className="price-item-w">
                      {(item.price * item.quantity).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              {item.stock_status === "onbackorder" ? (
                <div className="out-of-stock">
                  <MdRemoveShoppingCart className="icon" />
                  <span>Out of Stock</span>
                </div>
              ) : (
                <button
                  className="add-to-cart-button"
                  onClick={(e) => handleAddToCart(e, item)}
                >
                  Add to cart
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="bottom-section">
          <Link to="/categories" className="shopping">
            Continue shopping
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhishListPopup;
