import React, { useEffect, useState } from 'react';
import './ResetPassword.scss';
import mainImage from '../../assets/image 44.svg';
import logo from '../../assets/Black.svg';
import sent from '../../assets/ent.svg';
import { Link } from 'react-router-dom';
import {
  IconButton,
  InputAdornment,
  InputBase,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { BsChevronLeft } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (
      confirmPassword &&
      password &&
      password === confirmPassword &&
      password.length > 7
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword]);

  return (
    <div className="auth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password</title>
        <link
          rel="canonical"
          href={`${domain}/auth/reset-password`}
        />
      </Helmet>
      <div className="wrapper">
        <div className="left-div">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="logo-img"
            />
          </Link>

          <img
            src={mainImage}
            alt="main"
            className="bottom-img"
          />
        </div>

        <div className="right-div">
          <Link to="/" className="home-link">
            <BsChevronLeft />
            back to home page
          </Link>
          {!isSuccess ? (
            <div className="form-div">
              <div className="title-div">
                <p className="title">Reset password</p>
                <h1 className="slogan">
                  By entering your new password it will be
                  updated!
                </h1>
              </div>
              <div className="inputs">
                <div className="input-div">
                  <div className="password-labels">
                    <label>New password</label>
                  </div>

                  <InputBase
                    className="mui-regiter-paasword"
                    onChange={(e) =>
                      setPassword(e.target.value)
                    }
                    placeholder="Enter your new password"
                    name="password"
                    type={
                      showPassword ? 'text' : 'password'
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((show) => !show)
                          }
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="input-div">
                  <div className="password-labels">
                    <label>Confirm new password</label>
                  </div>

                  <InputBase
                    className="mui-regiter-paasword"
                    onChange={(e) =>
                      setConfirmPassword(e.target.value)
                    }
                    placeholder="Enter your new password to confirm"
                    name="confirmedPassword"
                    type={
                      showPassword ? 'text' : 'password'
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((show) => !show)
                          }
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="submit-div">
                  <button
                    onClick={() => setIsSuccess(!isSuccess)}
                    className="submit-bttn"
                    disabled={disabled}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="passsword-changed">
              <div className="top-wrapper">
                <div className="title-div">
                  <span className="title">Great!</span>
                  <span className="status">
                    <img src={sent} alt="sent-icon" />
                    Password reset mail has been sent.
                  </span>
                </div>
                <span className="text">
                  Password reset email has been sent to the
                  email address on file for your account,
                  but may take several minutes to show up in
                  your inbox. Please wait at least 10
                  minutes before attempting another reset.
                </span>
              </div>
              <div className="bottom-wrapper">
                <span
                  style={{
                    color: '#0F172A',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Didn’t recieve the email?
                </span>
                <button className="resent">
                  Send again
                </button>
                <Link to="/auth/login">back to login</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
