import React, { useState, useEffect } from 'react';
import './Dropdown.css';
import logo from '../../assets/newLogo.PNG';
import { Link } from 'react-router-dom';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { BsChevronDown } from 'react-icons/bs';
import MiniCart from '../MiniCart/MiniCart';
import dropdown from '../../assets/dropdown.png';
import Close from '../../assets/Close.svg';
import { AiOutlineHeart } from 'react-icons/ai';
import WhishListPopup from '../WhishListPopup/WhishListPopup';
import { useSelector } from 'react-redux';
import { openInNewTab } from '../../util/outsideAlert';

const Dropdown = () => {
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showWish, setShowWish] = useState(false);

  const handleMega = (event, current) => {
    event.stopPropagation();
    if (current === showMenu) {
      setShowMenu(0);
    } else {
      setShowMenu(current);
    }
  };
  const cart = useSelector((state) => state.cart.products);
  const user = useSelector((state) => state.user.currentUser);

  const [cartCount, setCartCount] = useState(0);
  useEffect(() => {
    const getCartCount = () => {
      if (cart?.length > 0) {
        const cartQuantity = cart.reduce(
          (prev, current) => prev + current.quantity,
          0
        );
        setCartCount(cartQuantity);
      } else {
        setCartCount(0);
      }
    };
    getCartCount();
  }, [cart]);

  useEffect(() => {
    const bodyEl = document.body;
    if (showMobileMenu) {
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = 'auto';
    }
  }, [showMobileMenu]);

  return (
    <div className="Mobile_Navbar">
      {showWish && <WhishListPopup setShowWish={setShowWish} />}
      <MiniCart setShow={setShow} show={show} />
      <div className="nav-wrapper">
        <div className="left-box">
          <Link to={'/'} className="imglogo-navbar-mobile">
            <img src={logo} alt="logo" className="logo-mobile" />
          </Link>
        </div>
        <div className="right-box">
          <div className="bttn-wrapper">
            <button
              aria-label="Wish-List"
              className="cart-bttn-mobile"
              onClick={() => setShowWish(!showWish)}
            >
              <AiOutlineHeart />
            </button>
            <button
              aria-label="Cart"
              className="cart-bttn-mobile"
              onClick={() => setShow(true)}
            >
              <HiOutlineShoppingCart />
              {cartCount !== 0 && (
                <span className="cartCount">{cartCount}</span>
              )}
            </button>
          </div>
          <div onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <img src={showMobileMenu ? Close : dropdown} alt="dropdown" />
            {showMobileMenu && (
              <div className="dropdown-links-wrapper">
                <Link to="/categories">Shop</Link>
                <div
                  className="drop-down-link MobDrop"
                  to="/services"
                  onClick={(event) => handleMega(event, 1)}
                >
                  <div className="supmenuparent">
                    Services
                    <BsChevronDown style={{ color: 'black' }} />
                  </div>
                  {showMenu === 1 && (
                    <div className="MobDrop">
                      <Link
                        to="/services?tab=1"
                        className="menu-box Mobbox"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Compression Stockings
                          </span>
                          <span className="menuitempara">
                            Comfort & improved circulation with our compression
                            stockings.
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="/services?tab=4"
                        className="menu-box Mobbox"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Medication Review
                          </span>
                          <span className="menuitempara">
                            Ensuring safe and effective use of your medications.
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="/services?tab=3"
                        className="menu-box Mobbox"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Flu Shot & Vaccines
                          </span>
                          <span className="menuitempara">
                            Protect yourself with our free seasonal
                            immunizations.
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="/services?tab=2"
                        className="menu-box Mobbox"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Compliance Packs
                          </span>
                          <span className="menuitempara">
                            Simplify your regimen with our organized medication
                            packs.
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="/minoraliments"
                        className="menu-box Mobbox"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">Minor Aliments</span>
                          <span className="menuitempara">
                            Protect yourself with our free seasonal
                            immunizations.
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        onClick={() =>
                          openInNewTab(
                            'https://bookmytest.ca/services/5198246445'
                          )
                        }
                        className="menu-box Mobbox"
                        // onClick={() =>
                        //   setShowMobileMenu(false)
                        // }
                      >
                        <div className="text-div">
                          <span className="menuitemtitle">Covid-19 Test</span>
                          <span className="menuitempara">
                            Quick and reliable COVID-19 testing.
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
                <div
                  className="drop-down-link MobDrop"
                  to="/compounding"
                  onClick={(event) => handleMega(event, 2)}
                >
                  <div className="supmenuparent">
                    Compounding
                    <BsChevronDown style={{ color: 'black' }} />
                  </div>
                  {showMenu === 2 && (
                    <div className="MobDrop">
                      <Link to="/CFPatients" className="menu-box Mobbox">
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Compounding for Patients
                          </span>
                          <span className="menuitempara">
                            Customized medications tailored to your unique
                            healthcare needs.
                          </span>
                        </div>
                      </Link>
                      <Link to="/CFPhysicians" className="menu-box Mobbox">
                        <div className="text-div">
                          <span className="menuitemtitle">
                            Compounding for Physicians
                          </span>
                          <span className="menuitempara">
                            Crafting patient-specific medications to enhance
                            therapeutic outcomes.
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
                <Link to="/IdealProteinLandingPage">Ideal Protein</Link>
                <Link to="/FillPrescriptionOnlinePage">Prescription</Link>
                <Link to="/About">About us</Link>
                <Link
                  to="#"
                  onClick={() =>
                    openInNewTab('https://bookmytest.ca/services/5198246445')
                  }
                >
                  Covid-19 Test
                </Link>
                <Link to="/Contact">Contact Us</Link>
                {user ? (
                  <Link to={'/my-account/dashboard'}>My Account</Link>
                ) : (
                  <Link to={'/auth/login'}>Login</Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
