import React from 'react';
import NavBar from '../../components/Navbar/Navbar';
import Banner from '../../components/banner/Banner';
import Footer from '../../components/Footer/Footer';
import './privacy.css';
import Privacyimg from '../../assets/Privacy.png';
import Drowpdown from '../../components/Navbar/Dropdown';
import { Slide, Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const Privacy = () => {
  return (
    <div className="Privacy-Wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy </title>
        <link rel="canonical" href={`${domain}/Privacy`} />
      </Helmet>
      <Banner />
      <NavBar />
      <Drowpdown />
      <div className="Privacy_banner_section_wrapper">
        <div className="Privacy_banner_section">
          <Fade triggerOnce>
            <img src={Privacyimg} alt="Privacy" />
          </Fade>
        </div>
      </div>

      <div style={{ background: '#F8FAFC' }}>
        <div className="Privacy_text_wrapper">
          <div className="text_block">
            <p>
              Chancellors Way Pharmacy ("us", "our", or
              "we") is committed to respecting the privacy
              rights of its customers, visitors, and all
              users of our services across our various
              platforms (collectively referred to as the
              “Site”). We believe it's essential for you to
              understand the nature, purpose, and extent of
              information we collect. This policy details
              our practices regarding the collection, use,
              and sharing of your personal information.
              Should you have any queries, please feel free
              to contact us.
            </p>
          </div>
          <div className="text_block">
            <h1>APPLICATION OF THIS PRIVACY POLICY</h1>
            <h2>
              This Privacy Policy delineates the nature of
              Personal Information you might furnish while
              using our Site or the services that fall under
              our purview. Specifically, our services
              include:
            </h2>
            <p>
              <ul>
                <Slide
                  duration={400}
                  direction="left"
                  damping={0.5}
                  triggerOnce
                  cascade
                >
                  <li>
                    Websites that link to this Privacy
                    Policy, including those optimized for
                    mobile viewing.
                  </li>
                  <li>
                    Features and services available through
                    our websites, such as prescription
                    orders and refills (collectively
                    referred to as the “Services”).
                  </li>
                  <li>
                    Please note that this Privacy Policy
                    does not extend to third-party websites
                    or applications that might be linked to
                    our Site or the Services.
                  </li>
                </Slide>
              </ul>
            </p>
          </div>
          <div className="text_block">
            <h2>DEFINITION OF “PERSONAL INFORMATION”</h2>
            <p>
              Within the context of this document, “Personal
              Information” pertains to information that can
              identify a user or, given the context, could
              be leveraged to identify a user. Such
              information can be actively provided by you or
              passively collected by our systems. However,
              aggregated data, which cannot reasonably
              pinpoint an individual user, is not considered
              Personal Information.
            </p>
          </div>
          <div className="text_block">
            <h2>COLLECTION OF PERSONAL INFORMATION</h2>
            <p>
              We only become privy to Personal Information
              like names, email addresses, health records,
              physical addresses, telephone numbers,
              demographic details, etc., when users
              voluntarily provide them. Examples include
              service registration, feedback, contest
              entries, or service usage. We may consolidate
              Personal Information with other data we
              collect from different sources.
            </p>
            <p>
              Routine server operations record non-personal
              details of users, capturing aspects like the
              operating system (e.g., iOS, Android, Windows)
              and browser type (e.g., Chrome, Firefox,
              Safari). Our systems also maintain log files
              that log data like IP addresses, browser type,
              service provider details, timestamps, and user
              navigation patterns.
            </p>
            <p>
              Moreover, we employ various technologies, such
              as cookies and web beacons, to gather Personal
              Information. Primarily, cookies enhance user
              experiences by preserving their preferences.
              Furthermore, we might track location data
              linked to your IP address or, with your
              consent, the GPS or Bluetooth data from your
              mobile device. If you modify these settings on
              your device, some app functionalities may
              become unavailable.
            </p>
            <p>
              Mainly, we use this data to elevate the user
              experience. Other applications of this data
              include internal reviews of user numbers but
              in non-personal, aggregated formats.
            </p>
          </div>
          <div className="text_block">
            <h2>USE OF PERSONAL INFORMATION</h2>
            <h3>
              We leverage the acquired Personal Information
              for diverse intentions, such as:
            </h3>
            <p>
              <ul>
                <Slide
                  duration={400}
                  direction="left"
                  damping={0.5}
                  triggerOnce
                  cascade
                >
                  <li>
                    Authenticating your identity and
                    background details.
                  </li>
                  <li>
                    Facilitating your requests related to
                    our Services.
                  </li>
                  <li>
                    Comprehending both current and
                    forthcoming requirements of our Users.
                  </li>
                  <li>
                    Assessing your persistent qualification
                    for our Services.
                  </li>
                  <li>
                    Sharing updates about your membership,
                    the Services, or any other relevant
                    insights.
                  </li>
                  <li>
                    Showcasing products or services from
                    Chancellors Way Pharmacy or associated
                    third parties that may pique your
                    interest.
                  </li>
                  <li>
                    Enhancing our Services and fortifying
                    our relationship with you.
                  </li>
                  <li>
                    Disseminating digital advertisements,
                    inclusive of online and email
                    promotions, in adherence to legal
                    bounds.
                  </li>
                  <li>
                    Catering to any other mandates or
                    permissions specified by the law.
                  </li>
                  <li>
                    For added convenience, we might relay
                    your prescription details to your
                    insurance provider to expedite
                    reimbursement processes.
                  </li>
                </Slide>
              </ul>
            </p>
            <p>
              Our communication streams with you span
              various channels: phone, email, your member
              account, or postal mail, hinging on the
              details you've provided. Do note, for quality
              assurance, telephone communications may be
              recorded.
            </p>
            <p>
              We may share Personal Information with
              entities termed “authorized recipients”, but
              this is exclusively within the boundaries of
              this Privacy Policy’s objectives. This might
              involve understanding user patterns,
              addressing user-specific or legal queries,
              sending notifications, safeguarding our site
              and services, processing membership
              applications, verifying credit card
              transactions, or overseeing legal and
              insurance claims. In some instances, with due
              legal permissions, we might share your data
              with regulatory bodies or other public
              entities. Furthermore, Personal Information
              might be populated through online forms linked
              to our Services.
            </p>
            <p>
              Upon feeding Personal Information on our
              platform, identifying technologies may enable
              the platform to “recall” your preferences,
              like frequently visited sections. This data
              might also be harnessed to introduce you to
              new service alerts, invite you to user
              surveys, or inform you of unique promotions.
              We maintain Personal Information only for a
              duration justified by its purpose—be it
              customer service, resolving queries, or
              addressing technical hitches. Exceptions might
              arise due to legal obligations or for
              establishing or defending legal stances.
            </p>
          </div>
          <div className="text_block">
            <h2>CONSENT</h2>
            <p>
              Your awareness and approval are paramount for
              gathering, employing, or revealing your
              Personal Information, barring instances where
              consent is legally redundant. Your engagement
              with this Privacy Policy inherently signifies
              your consent. Notably, this Privacy Policy
              integrates into your membership agreement for
              the Services.
            </p>
            <h3>
              There are scenarios wherein your Personal
              Information might be disclosed sans consent,
              including but not limited to:
            </h3>
            <p>
              <ul>
                <Slide
                  duration={400}
                  direction="left"
                  damping={0.5}
                  triggerOnce
                  cascade
                >
                  <li>
                    Situations where immediate consent is
                    elusive, yet the disclosure is in your
                    interest.
                  </li>
                  <li>Debt collection.</li>
                  <li>
                    Interactions with Canadian public bodies
                    or law enforcement related to offences
                    or investigations.
                  </li>
                  <li>Legal compulsions.</li>
                </Slide>
              </ul>
            </p>
            <p>
              If you wish to retract your consent regarding
              the collection, use, and disclosure of your
              Personal Information, you can reach out to us
              via the provided email address, always keeping
              in mind the constraints of the prevailing law.
            </p>
          </div>
          <div className="text_block">
            <h2>CONSOLIDATION OF PERSONAL INFORMATION</h2>
            <p>
              We may integrate Personal Information from
              individuals engaging with our Site and
              Services. Such consolidated data aids in
              refining the Site and Services, enhancing our
              communication, enriching our marketing
              strategies, and bolstering other operational
              functions.
            </p>
          </div>
          <div className="text_block">
            <h2>
              LIMITATIONS ON GATHERING PERSONAL INFORMATION
            </h2>
            <p>
              You have the discretion to limit the volume
              and variety of Personal Information you
              actively share. You can opt not to input any
              Personal Information on the Site or within the
              Services. However, certain Services might
              mandate the provision of requisite Personal
              Information. Some sections of the Site may
              also offer choices to either opt-in or opt-out
              of specific promotional communications or
              additional services. Preference settings may
              be available, allowing you to dictate if you'd
              like the Site to utilize tracking
              technologies, such as cookies, for subsequent
              visits. Nevertheless, if the Site harnesses
              tracking tools to accumulate non-identifiable
              data, opt-out choices for such mechanisms
              might be unavailable. Several browsers
              facilitate the restriction or deactivation of
              passive data collection tools. For in-depth
              guidance on managing cookies or passive
              transmissions, peruse your browser's official
              website.
            </p>
          </div>
          <div className="text_block">
            <h2>
              TRANSMISSION OF PERSONAL INFORMATION TO
              EXTERNAL ENTITIES
            </h2>
            <p>
              Unless communicated at the point of
              collection, Personal Information remains
              confidential and is not traded, transmitted,
              or unveiled, barring authorized recipients and
              legally mandated scenarios. It's essential to
              recognize that sites accessible via our
              platform may adhere to distinct protocols. We
              do not bear responsibility for the
              methodologies or content of these third-party
              sites. We, or a partnering entity, might
              employ cookies or web beacons to glean
              insights about your interaction with the Site,
              enabling tailored advertising relevant to your
              interests. Third-party advertising entities
              may utilize technology to optimize the ads
              showcased during your Site engagement. These
              entities automatically identify your IP
              address, employing other tools (like cookies
              or web beacons) to augment their advertising
              efficacy. Our jurisdiction over cookies used
              by third-party advertisers is limited. To
              comprehend their practices and opt-out
              provisions, refer to their distinct privacy
              policies. Lastly, we retain the prerogative to
              disclose Personal Information in alignment
              with governmental mandates, national security
              concerns, or as compelled by the judiciary.
            </p>
          </div>
          <div className="text_block">
            <h2>USE OF COOKIES</h2>
            <p>
              Our platform, upon your visit or Service
              utilization, might store temporary data on
              your device through “cookies” or web beacons.
              These tools aid in personalizing your online
              experience, collating statistics, identifying
              trends, and overall enhancing the Site and
              Services. Some Site and Service facets might
              hinge on the activation of such passive data
              collection tools. Their intent isn't to
              profile your actions or unlawfully garner
              Personal Information. Majority of browsers
              enable you to manage cookies—deleting,
              blocking, or alerting prior to storage. For
              further clarity on these functionalities,
              consult your browser or device's official
              documentation.
            </p>
          </div>
          <div className="text_block">
            <h2>SECURITY OF PERSONAL INFORMATION</h2>
            <p>
              At Chancellors Way Pharmacy, the sanctity of
              your Personal Information is paramount. We
              employ apt measures aligned with the data's
              sensitivity. All team members adhere to
              stringent confidentiality standards. Multiple
              security layers safeguard against unauthorized
              interference, alterations, or disclosures.
              Third-party collaborations are governed by
              contractual clauses ensuring your data's
              protection. Your Personal Information, when
              shared with partners or housed outside of
              Canada, might come under the jurisdiction of
              that region's legal framework. While we strive
              to fortify data during your online engagement,
              internet-transmitted data's integrity isn't
              absolute. Caution is advised when sharing
              Personal Information online. Remember, no
              digital platform is entirely impervious to
              breaches.
            </p>
          </div>
          <div className="text_block">
            <h2>
              LINKS TO OTHER WEBSITES AND APPLICATIONS
            </h2>
            <p>
              Our Site might feature links redirecting to
              other platforms. Each platform has its unique
              stance on data handling and security. We
              aren't accountable for external entities' data
              practices. Prior to sharing data with external
              sites or applications, acquaint yourself with
              their privacy guidelines and terms, ensuring
              alignment with your comfort level. Despite
              content suggesting otherwise on our Site, we
              neither endorse nor validate any external
              platform. Navigating to linked sites is solely
              at your discretion. We highly recommend
              scrutinizing third-party platforms' privacy
              stances prior to any data sharing. We disclaim
              responsibility for any repercussions stemming
              from interactions with linked sites.
            </p>
          </div>
          <div className="text_block">
            <h2>CHILDREN-RELATED PERSONAL INFORMATION</h2>
            <p>
              For parents or legal guardians, in alignment
              with the standards set by the Office of the
              Privacy Commissioner of Canada, Chancellors
              Way Pharmacy is dedicated to safeguarding the
              privacy of children online. We do not
              purposefully or knowingly acquire, utilize, or
              disclose Personal Information of individuals
              below the age of majority. We advocate for
              parents or guardians to monitor their
              children’s online activities and consider
              utilizing parental control tools provided by
              online services and software manufacturers to
              create a child-safe online environment. These
              tools can also inhibit children from divulging
              their personal information without parental
              consent. If we identify any registration
              attempt by a child under the age of majority,
              we will reject the registration and endeavor
              to erase any provided information. Should you
              be aware of a child providing Personal
              Information, we urge you to reach out to us
              for its deletion.
            </p>
            <h3>
              If you have not reached the age of majority,
              refrain from sharing any Personal Information
              with us.
            </h3>
          </div>
          <div className="text_block">
            <h2>PRIVACY LEGISLATION</h2>
            <p>
              Our handling of Personal Information is
              chiefly regulated by Ontario’s Personal
              Information Protection Act, the federal
              Privacy Act, the Office of the Privacy
              Commissioner of Canada, Canada’s Anti-Spam
              Legislation, the Telecommunications Act, and
              other pertinent privacy laws corresponding to
              the user's jurisdiction (“Privacy
              Legislation”). Any ambiguity or conflict
              between our practices and the Privacy
              Legislation will be resolved in compliance
              with the relevant laws.
            </p>
          </div>
          <div className="text_block">
            <h2>
              ACCEPTANCE OF PRIVACY TERMS & CONDITIONS
            </h2>
            <p>
              Your interaction with our Sites and Services
              is governed by this Privacy Policy, Privacy
              Legislation, and all other relevant laws.
              Chancellors Way Pharmacy holds the right to
              modify this Privacy Policy at any given time,
              without prior notice, to adapt to
              technological evolution, legal amendments, and
              ethical business practices. Should there be
              alterations in our privacy practices, an
              updated Privacy Policy will be available,
              highlighting the changes and indicating the
              new effective date. You are subject to any
              revisions and are encouraged to periodically
              review the current Privacy Policy. By engaging
              with the Site and/or Services, you acknowledge
              your understanding and acceptance of this
              Privacy Policy.
            </p>
            <p>
              For inquiries regarding the modification or
              deletion of Personal Information you’ve shared
              with us, or to opt-out of future
              communications, please reach out through the
              “Contact” link on our Site or email
              Chancellors WayPharmacy204@gmail.com. Include
              the email address used for registration, the
              website or application through which you’ve
              shared Personal Information, and a detailed
              description of your request. For deletion or
              amendment requests via email, please specify
              “Deletion Request” or “Amendment Request” in
              the subject line. We commit to addressing all
              reasonable requests promptly.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
