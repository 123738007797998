import React from 'react';
import NavBar from '../../components/Navbar/Navbar';
import Banner from '../../components/banner/Banner';
import Footer from '../../components/Footer/Footer';
import '../Privacy/privacy.css';
import termsimg from '../../assets/terms.png';
import Drowpdown from '../../components/Navbar/Dropdown';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';

const terms = () => {
  return (
    <div className="Privacy-Wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms </title>
        <link rel="canonical" href={`${domain}/Terms`} />
      </Helmet>
      <Banner />
      <NavBar />
      <Drowpdown />

      <div className="Privacy_banner_section_wrapper">
        <div className="Privacy_banner_section">
          <Fade triggerOnce>
            <img src={termsimg} alt="Privacy" />{' '}
          </Fade>
        </div>
      </div>

      <div style={{ background: '#F8FAFC' }}>
        <div className="Privacy_text_wrapper">
          <div className="text_block">
            <h1 className="heading-one">
              INTRODUCTION AND DEFINITIONS
            </h1>
            <p>
              Welcome to Chancellors Way Pharmacy! The
              following terms of use and end user license
              agreement (the “Terms of Use”) constitute an
              agreement between you (collectively, “user”,
              “you” or “your”) and the dispensing pharmacy
              licensing the “Chancellors Way Pharmacy” name
              and software for your local jurisdiction (as
              indicated below) and its affiliates and
              subsidiaries (collectively, “Chancellors Way
              Pharmacy”, “our”, “us” or “we”). These Terms
              of Use govern your use of and access to the
              Site, Content and/or Services (each as
              specified below) and are a legally binding
              agreement between you and Chancellors Way
              Pharmacy.
            </p>
          </div>
          <div className="text_block">
            <h2 id="heading-two">
              For the purposes of these Terms of Use:
            </h2>
            <p>
              “Content” means any expression fixed in a
              tangible medium and includes, without
              limitation, ideas, text, comments, video,
              audio, images, graphics, designs, drawings,
              animations, logos, trademarks, copyrights,
              information, data, software, scripts, tasks,
              activities, and any intellectual property
              therein, any of which may be created,
              submitted, or otherwise made accessible on or
              through the Site and/or Services.
              <br />
              “Minor” means a person under the age of
              majority in the jurisdiction where the
              dispensing pharmacy is located.
              <br />
              “Patient Representative” means a person who
              consents to act and is authorized to act on
              the patient’s behalf to manage the user’s
              prescriptions and Services in compliance with
              applicable laws and regulations.
              <br />
              “Services” means all services made available
              by Chancellors Way Pharmacy, including but not
              limited to services accessed through the
              Content or the Site.
              <br />
              “Site” means https://www.Chancellors
              Waypharmacy.ca, its related webpages and sites
              (including, without limitation, any mobile
              optimization website), and Chancellors Way
              Pharmacy’s mobile applications.
            </p>
          </div>
          <div className="text_block">
            <h2>
              ACCEPTANCE OF TERMS AND CHANGES AND REVISIONS
            </h2>
            <p>
              Carefully review these Terms of Use and, if
              you do not agree with them, you are not
              permitted to access or use the Site, Content
              or Services and you should exit the Site,
              cease using any or all of the Site, Content or
              Services immediately and, if you have
              downloaded or installed any of the Content on
              to your devices, uninstall the Content from
              such devices immediately.
              <br />
              By accessing the Site, submitting information
              to us or downloading, installing or using any
              of our Content or Services you accept these
              Terms of Use. If you do not accept these Terms
              of Use you must not submit information to or
              register an account with us, access the Site
              or download, install or use any of the Content
              or Services. If you are a Minor, you are not
              permitted to access or use the Site, Content
              or Services and you must exit the Site and
              cease using the Site, Content and Services
              immediately and have a parent or guardian
              access or use the Site, Content and Services
              on your behalf.
              <br />
              We reserve the right, acting in our sole
              discretion and at any time, to revise these
              Terms of Use, including the Privacy Policy
              (defined below). We will give you notice of
              such revisions by posting the revisions to the
              Site. It is your responsibility to ensure that
              you are aware of the current Terms of Use when
              you access or use the Site, Content or
              Services. If you continue to access or use any
              of the Site, Content or Services after any
              revisions to these Terms of Use are posted,
              you will be deemed to have accepted those
              revisions.
            </p>
          </div>
          <div className="text_block">
            <h2>Changes to Chancellors Way Pharmacy:</h2>
            <h3>
              Chancellors Way Pharmacy reserves the right,
              acting in its sole discretion and with or
              without prior notice to you, to do any of the
              following:
            </h3>
            <p>
              <ul>
                <li>
                  modify, suspend or terminate operation of,
                  or access to, any portion, features or
                  functions of the Site, Content or Services
                  including, but not limited to, hours of
                  availability, geographical availability
                  and applicable policies or terms; make
                  changes to any fees or charges, if any,
                  related to your use of the Site, Content
                  or Services; make changes to the
                  equipment, hardware or software required
                  to use and access the Site, Content or
                  Services; and interrupt the Site, Content
                  or Services, or any portion thereof, as
                  necessary to perform routine or
                  non-routine maintenance, error correction
                  or other modifications. Updates and
                  Discontinuance of Site or Services
                </li>
                <li>
                  make changes to any fees or charges, if
                  any, related to your use of the Site,
                  Content or Services;
                </li>
                <li>
                  make changes to the equipment, hardware or
                  software required to use and access the
                  Site, Content or Services; and
                </li>
                <li>
                  interrupt the Site, Content or Services,
                  or any portion thereof, as necessary to
                  perform routine or non-routine
                  maintenance, error correction or other
                  modifications.
                </li>
                <li>
                  Updates and Discontinuance of Site or
                  Services
                </li>
              </ul>
            </p>
            <p>
              You acknowledge and agree that Chancellors Way
              Pharmacy may update, modify, patch or
              discontinue any aspect of the Site or
              Services, by remotely installing updates and
              patches or otherwise, without your consent or
              approval and with or without prior, or any,
              notice to you and that the Terms of Use apply
              to those updates and modifications. We do not
              have any obligation to provide updates or
              modifications or to continue to provide
              particular Services.
            </p>
            <p>
              Chancellors Way Pharmacy reserves the right,
              at any time and without notice or liability to
              you, to update, modify or reset certain
              parameters of the Site or Services any of
              which may impact your use of the Site and/or
              Services.
            </p>
            <h3>Laws and Regulations:</h3>
            <p>
              Your access to and use of the Site, Content or
              Services is subject to all applicable
              international, federal, provincial, state and
              local laws and regulations.
            </p>
          </div>
          <div className="text_block">
            <h2>USE OF SERVICES AND YOUR ACCOUNT</h2>
            <h3>Your Account and Security</h3>
            <p>
              In order to access and use certain features of
              the Site or Services you may be required to
              register an account (the “Account”) through
              the Site or Services. When you register or
              update an Account, you will be required to
              provide us with certain personal information,
              which may include your name, e-mail address,
              telephone number, date of birth, care card or
              provincial personal health number, medication
              and prescription information, information
              regarding allergies, medical history, gender,
              insurance information, payment information,
              selected user name and password (the “Login
              Information”), and to grant express consent to
              Chancellors Way Pharmacy to contact you
              through the telephone number and e-mail
              address provided. This Login Information will
              be retained and used by Chancellors Way
              Pharmacy in accordance with our Privacy Policy
              . You agree that all Login Information
              provided by you will be accurate and complete
              and that you will promptly update such Login
              Information if and when it changes.
            </p>
            <h3>Patient Representative</h3>
            <p>
              You may add a family member or caregiver to
              your Account as your Patient Representative
              for the purpose of that Patient Representative
              managing your prescriptions and Services on
              your behalf. You are responsible for ensuring
              that the contact information for this Patient
              Representative is current, complete, and
              accurate. The act of adding a Patient
              Representative to your account will give
              access and constitutes deemed consent for that
              individual to manage your prescriptions and
              Services on your behalf. This Patient
              Representative will then be contacted and
              asked to provide his or her consent to be your
              Patient Representative. This Patient
              Representative must not be a minor. After this
              process is completed, your Patient
              Representative will have the ability to manage
              your prescriptions and Services as an
              additional user whose Account is added to your
              Account.
            </p>
            <p>
              You may also add a family member or person to
              whom you are a caregiver or representative to
              your Account for the purposes of managing this
              user’s prescriptions and Services as a Patient
              Representative. If this user is an adult, you
              must provide his or her basic contact
              information. You are responsible for ensuring
              that the contact information for this user is
              current, complete, and accurate. By adding a
              user to whom you are a caregiver or
              representative to your account, you are deemed
              to consent to being a Patient Representative
              for this user. This user will then be
              contacted and asked to provide his or her
              consent for you to be his or her Patient
              Representative. You may also act as a Patient
              Representative for a minor to whom you are a
              parent or legal guardian. The minor’s consent
              is not required. After this process is
              completed, you will have the ability to manage
              the user’s prescriptions and Services through
              your Account.
            </p>
          </div>
          <div className="text_block">
            <h2>Service Monitoring and Suspension</h2>
            <p>
              Chancellors Way Pharmacy may monitor,
              terminate, or suspend your Account or our
              Services at any time.
              <br />
              Chancellors Way Pharmacy may immediately, and
              without prior notice, terminate your ability
              to access each or both of the Site or Services
              or portions thereof for any reason including,
              without limitation: (i) if you violate or
              otherwise fail to strictly comply with any
              term or provision of these Terms of Use; (ii)
              if we have determined that your use has
              created or potentially created risk or legal
              exposure for Chancellors Way Pharmacy; or
              (iii) in response to requests by law
              enforcement or other government agencies. Upon
              termination of your access to the Site or
              Services or portions thereof for any reason,
              you must cease use of your Account(s), cease
              accessing the Site and Services, and promptly
              destroy all electronic and other copies of all
              Content, including all related documentation.
              <br />
              You acknowledge and agree that any termination
              or suspension of your Account may be made by
              us in our sole discretion and that we shall
              not be liable to you or any third-party for
              any termination or suspension of your Account,
              your access to the Site, or Services.
              <br />
              Following any suspension of Services or
              termination of your Account, upon request,
              Chancellors Way Pharmacy will transfer your
              prescription to a pharmacy licensed in Canada
              of your choosing if the drug does not contain
              a controlled substance, and the transfer
              occurs between a registrant and another
              registrant or an equivalent of a registrant in
              another Canadian jurisdiction.
            </p>
            <h3>
              Consent to Receive Electronic and Telephonic
              Communications
            </h3>
            <p>
              You consent to receive telephonic
              communications from Chancellors Way Pharmacy
              and electronic communications either in the
              form of email, SMS, WhatsApp, Facebook
              Messenger, push notification to your mobile or
              electronic device, or other forms of
              electronic messages sent to you at the
              addresses and accounts listed on your Account
              or by communications posted on or
              notifications through the Site (collectively,
              “Electronic Communications”). You acknowledge
              and agree that any Electronic Communication
              shall satisfy any legal requirement that such
              communication or notification be in writing.
            </p>
            <p>
              Upon registering an Account, you may receive
              Electronic Communications regarding the
              operations of our Services. You cannot opt out
              of receiving these communications while you
              continue to use our Services. You may also
              receive promotional Electronic Communications
              while you use our Services. You can opt out of
              these Electronic Communications at any time by
              using the unsubscribe feature located in the
              footer of such Electronic Communications,
              where applicable, or by managing your Account
              settings accordingly. You may also use the
              settings on your mobile or other electronic
              device to disable push notifications at any
              time. You cannot opt out of Electronic
              Communications in the form of general notices
              on the Site to all users.
            </p>
            <h3>Text and Mobile Messaging Consents</h3>
            <p>
              Without limiting the generality of the
              foregoing, by downloading or using our
              applications, the Site, and the Services you
              agree that we may communicate with you
              regarding our Site or Services or respond to
              your communications to us through the Services
              by Electronic Communications, including SMS,
              MMS, text message, push notifications, or
              other electronic means directed to your mobile
              device.
            </p>
          </div>
          <div className="text_block">
            <h2>DRUG DISPENSING REQUIREMENT POLICIES</h2>
            <h3>General</h3>
            <p>
              You must have a valid prescription from a
              physician licensed to practice medicine and
              prescribe medications in the province or
              territory where you live. Please note that
              only your doctor can fax a prescription to
              Chancellors Way Pharmacy; pharmacy regulations
              stipulate that a pharmacy can accept a faxed
              prescription only from a physician’s office.
              <br />
              Chancellors Way Pharmacy accepts prescriptions
              transferred from your old pharmacy. If you
              provide us with the name and contact
              information of your old pharmacy, as well as
              the names of the medications you filled there,
              we will coordinate the transfer for you.
              <br />
              Chancellors Way Pharmacy also accepts
              prescriptions sent to our office by mail. To
              save time, you can use the Site to take a
              picture of your prescription and submit it
              directly to us. We will send you a prepaid,
              priority envelope to mail us your
              prescription, since we cannot ship your
              medication without a physical copy of your
              prescription. We use the picture to sort and
              package your medication ahead of time so your
              medication is ready to ship as soon as we
              receive your prescription in the mail.
              <br />
              If your prescription changes, have your doctor
              fax it directly to Chancellors Way Pharmacy,
              and we will update your file.
              <br />
              Chancellors Way Pharmacy fills prescriptions
              by the dose in SmartPacks. You can order up to
              30 days’ worth of your prescription
              medication, which can be refilled in 30-day
              increments until you reach the end of your
              prescription. Your SmartPacks will also
              contain 30 days’ worth of any over-the-counter
              medications you have ordered, like vitamins
              and supplements. We provide other pharmacy
              items, like diabetic supplies, in 3 month
              increments or on an as-needed basis." is not
              100% accurate. We dispense medications in both
              vials and blister packaging. Blister packaging
              are unit dosed organizers that are filled in
              7/14/28 day increments. Patients can request
              their medication in 1/2/3 month intervals in
              vials. 
              <br />
              Chancellors Way Pharmacy will not ship any
              pharmaceutical product to you that does not
              have Health Canada Approval.
              <br />
              Before any prescription is released for
              shipping, a Chancellors Way Pharmacy
              pharmacist will be in contact with you to
              discuss your medication for counselling, based
              on the time you specify in your profile when
              you sign up. If you have a clinical question,
              you can privately consult with a pharmacist at
              any time by calling in, emailing, or using the
              in-app chat feature. If you have clinical
              questions about your medication, pharmacists
              are on call from Monday to Friday from 9.00 am
              to 5:00 pm, PST." I don't think we'll be
              utilizing any in-app chatting feature. Also
              our hours of operation are Monday to Thursday
              9-6 EST, Friday 9-5 EST and Saturday 9-1 EST. 
            </p>
          </div>
          <div className="text_block">
            <h2>
              Requests for and Transfers of Prescriptions
            </h2>
            <p>
              Upon registering an Account and adding your
              Payment Method (as described below), you give
              Chancellors Way Pharmacy your consent to
              transfer your prescriptions from your existing
              pharmacy(s) and/or contact your prescriber(s)
              regarding your prescriptions.
            </p>
          </div>
          <div className="text_block">
            <h2>Narcotics Policy</h2>
            <p>
              Chancellors Way Pharmacy does not dispense
              narcotics, controlled and targeted substances.
              You should continue to have prescriptions for
              these medications filled for you at another
              pharmacy". We do dispense narcotics, controls
              and targeted substances, we just wouldn't be
              able to deliver them outside Guelph and
              surrounding areas. 
            </p>
          </div>
          <div className="text_block">
            <h2>Generic Substitutions</h2>
            <p>
              Like most pharmacies, Chancellors Way Pharmacy
              dispenses generic medication when appropriate
              and permitted by the prescribing doctor.
              Generic medications are just as safe and
              effective as brand-name medications. All
              prescription drugs are reviewed by Health
              Canada against federal guidelines and as such,
              the generic drug must include the same amount
              of medicinal ingredients as branded drugs.
              Although the non-medicinal ingredients may
              differ, the generic drug manufacturer must
              perform studies showing that the non-medicinal
              ingredients do not change the drug’s safety,
              quality, or effectiveness.
            </p>
            <p>
              When Chancellors Way Pharmacy fills your
              medications, we keep your best interests at
              heart and look for alternative medications
              that provide you with the lowest cost without
              compromising your health. For example, price
              reductions are often seen through therapeutic
              substitution which can mean switching one
              medication for a chemically or therapeutically
              equivalent drug that can provide the same
              health benefits.
            </p>
          </div>
          <div className="text_block">
            <h2>Refills</h2>
            <p>
              If you have refills of your medications at
              another pharmacy, submit a transfer request of
              your prescription from the other pharmacy to
              Chancellors Way Pharmacy. You can submit a
              transfer request through the Site or by phone.
              <br />
              When your prescription is due for a refill,
              Chancellors Way Pharmacy will notify you by
              phone or Electronic Communication, based on
              the preference you indicated when you created
              your Account. When you receive a refill
              notification, access the Site and you will be
              shown how much medication you have left on
              your prescription and be given the option to
              refill your prescription, which will be
              shipped to you directly. When you are running
              out of refills, Chancellors Way Pharmacy will
              contact your doctor (when available) to get a
              new prescription. We will notify you promptly
              if we run into any issues with renewal.
              <br />
              If you need to refill a prescription early,
              are planning on travelling or going on
              vacation, call our office and let us know the
              dates you will be away at your earliest
              convenience. Occasionally, you may experience
              higher costs when requesting an early fill, or
              vacation supply. However, we will work with
              your insurance provider to maximize the
              coverage of your medications – contact our
              office by phone, email, or through our in-app
              chat feature and we will go over your options.
            </p>
          </div>
          <div className="text_block">
            <h2>Safety and Quality</h2>
            <p>
              Chancellors Way Pharmacy advises you to always
              follow your doctor’s instructions for taking
              your medication: take the prescribed amount of
              the medication you should take for the
              prescribed length of time you should take it.
              This is the best thing you can do to make sure
              you get the full benefits from your medication
              and prevent additional health complications.
              We understand that some medications may have
              unpleasant side effects or be difficult to
              administer, and that is why Chancellors Way
              Pharmacy pharmacists are available to offer
              clinical advice when dealing with these
              issues.
              <br />
              Chancellors Way Pharmacy is fully accredited
              and licensed through its provincial College,
              Association, or Society. Chancellors Way
              Pharmacy only dispenses medications that have
              been approved for sale by Health Canada.
              <br />
              All medication filled by Chancellors Way
              Pharmacy is verified by computer and
              double-checked for accuracy by a licensed
              pharmacist for guaranteed accuracy. If your
              package arrives damaged or with any seals
              broken, let us know the tracking number
              provided with your package and we will replace
              it right away.
            </p>
          </div>
          <div className="text_block">
            <h2>Medication Disposal</h2>
            <p>
              It is important to dispose of medication,
              needles, syringes or lancets correctly.
              Chancellors Way Pharmacy does not accept
              returns of any of these items". We do accept
              yellow-sharp containers for disposal, we just
              can't have them delivered to us. Patient's can
              drop off full containers and we provide them
              an empty container free-of-charge. 
            </p>
          </div>
          <div className="text_block">
            <h2>Packaging and Child Safety Waiver</h2>
            <p>
              A pharmacy is required to dispense medication
              in a child-resistant container unless the
              patient has signed a waiver or verbally
              requested a non-safety vial or container or
              where a child-resistant container is not
              suitable due to the physical nature of the
              pharmaceutical.
            </p>
            <p>
              Blisterpacks/Dosettes are not child resistant
              and should be kept out of reach of children at
              all times. You are responsible for ensuring
              necessary safeguards are in place to keep
              children from accessing or tampering with your
              medications stored in clisterpacks. By using
              the site and services you are waiving the
              requirement for Chancellors Way Pharmacy to
              package your pharmaceuticals in child
              resistant containers for all new prescriptions
              and refills ordered from Chancellors Way
              Pharmacy or through the site.
            </p>
          </div>
          <div className="text_block">
            <h2>COMPLAINTS PROCEDURE</h2>
            <p>
              We take feedback very seriously. If you have
              any concerns regarding Chancellors Way
              Pharmacy or our services, please email us at
              info@Chancellors WayPharmacy.ca. We will
              respond promptly to follow up.
            </p>
          </div>
          <div className="text_block">
            <h2>
              DEVICES, MOBILE SERVICES AND MARKETPLACES
            </h2>
            <p>
              The Site may be available through certain
              mobile or other personal electronic devices.
              You acknowledge and agree that Chancellors Way
              Pharmacy is not responsible for ensuring that
              your device’s software is compatible with the
              Site or that you can use or access the Site or
              the Services through your particular device.
              If you are accessing the Services or the Site
              through a mobile device, you should check with
              your carrier to understand what voice, data
              and messaging rates will apply. In addition,
              the terms and conditions of the app store or
              marketplace from which you may have downloaded
              our application will also apply.
            </p>
            <h2>Application License</h2>
            <p>
              We hereby grant you a personal, non-exclusive,
              non-transferable, non-sub-licensable,
              revocable license to use our applications
              downloaded directly from a legitimate
              marketplace or app store, solely in object
              code format and solely for lawful purposes.
              With respect to any open source or third-party
              code that may be incorporated in the
              applications, such open source code is covered
              by the applicable open source or third-party
              end user license agreement, if any,
              authorizing use of such code.
            </p>
          </div>
          <div className="text_block">
            <h2>Digital Marketplaces and App Stores</h2>
            <h3>
              If you download our applications from a
              third-party marketplace or app store (the “App
              Provider”), you acknowledge and agree that:
            </h3>
            <p>
              <ul>
                <li>
                  the Terms of Use are an agreement between
                  us, and not with the App Provider; as
                  between Chancellors Way Pharmacy and the
                  App Provider, Chancellors Way Pharmacy is
                  solely responsible for the applications;
                </li>
                <li>
                  the App Provider has no obligation to
                  provide any maintenance and support
                  services with respect to the applications;
                </li>
                <li>
                  in the event of any failure of the
                  applications to conform to any applicable
                  warranty, (i) you may notify the App
                  Provider and the App Provider may refund
                  the purchase price for the applications to
                  you (if applicable), (ii) to the maximum
                  extent permitted by applicable law, the
                  App Provider will have no other warranty
                  obligation whatsoever with respect to the
                  applications, and (iii) any other claims,
                  losses, liabilities, damages, costs or
                  expenses attributable to any failure to
                  conform to any warranty will be, as
                  between Chancellors Way Pharmacy and the
                  App Provider, Chancellors Way Pharmacy’s
                  responsibility;
                </li>
                <li>
                  the App Provider is not responsible for
                  addressing any claims you have relating to
                  the applications or your possession and
                  use of the applications;
                </li>
                <li>
                  if a third party claims that an
                  application infringes another party’s
                  intellectual property rights, as between
                  the App Provider and Chancellors Way
                  Pharmacy, Chancellors Way Pharmacy will be
                  responsible for the investigation,
                  defense, settlement and discharge of any
                  such intellectual property infringement
                  claim to the extent required by these
                  Terms of Use;
                </li>
                <li>
                  the App Provider and its subsidiaries are
                  third-party beneficiaries of these Terms
                  of Use as it relates to your license to
                  the applications and, upon your acceptance
                  of these Terms of Use, the App Provider
                  will have the right (and will be deemed to
                  have accepted the right) to enforce these
                  Terms of Use as related to your license of
                  the applications against you as a
                  third-party beneficiary thereof; and
                </li>
                <li>
                  you must also comply with all applicable
                  third-party terms of service when using
                  the applications.
                </li>
              </ul>
            </p>
          </div>
          <div className="text_block">
            <h2>
              INFORMATION COLLECTION, OWNERSHIP AND USE
            </h2>
            <h3>Privacy</h3>
            <p>
              By accessing or using the Site, Content or
              Services or otherwise accepting or agreeing to
              these Terms of Use, you fully and completely
              agree to the terms and conditions of
              Chancellors Way Pharmacy’s Privacy Policy (the
              “Privacy Policy”) and to any terms and
              conditions incorporated therein by reference.
              The Privacy Policy sets out how, why and to
              what extent Chancellors Way Pharmacy collects
              and uses your personal and non-personal
              information in connection with your access to
              or use of the Site, Content or Services. The
              Privacy Policy, as amended from time to time,
              is available at Privacy Policy.
            </p>
            <h3>Transmission of Personal Data</h3>
            <p>
              You acknowledge and agree that by providing
              Chancellors Way Pharmacy with any personal or
              proprietary user information through the Site,
              Content or Services you consent to the
              transmission of such personal or proprietary
              user information over international borders as
              may be necessary for processing or storage in
              accordance with Chancellors Way Pharmacy’s
              standard business practices and Privacy
              Policy, subject to applicable laws which may
              prohibit Chancellors Way Pharmacy from
              transmitting certain personal information over
              international borders. To the extent required
              by applicable laws, Chancellors Way Pharmacy
              will maintain and store your personal
              information in Canada and not transmit such
              information across international borders. You
              should be aware that Linked Sites (as defined
              below) and any third party services which you
              may use to access the Content or Services may
              contain transmission of personal data
              provisions and be subject to privacy policies
              that differ from Chancellors Way Pharmacy’s
              Privacy Policy. Chancellors Way Pharmacy is
              not responsible for the policies of, or for
              the storage, handling or transmission
              practices or treatment of your personal
              information by Linked Sites and expressly
              disclaims any and all liability related
              thereto.
            </p>
            <h3>Feedback</h3>
            <p>
              Chancellors Way Pharmacy welcomes your ideas,
              suggestions and recommendations for Site
              features, functionality and enhancements
              (“Feedback”). Any Feedback you provide to
              Chancellors Way Pharmacy will be deemed
              non-confidential and Chancellors Way Pharmacy
              will be free to use such Feedback without
              restriction and without any compensation or
              obligations to you.
            </p>
          </div>
          <div className="text_block">
            <h2>E-COMMERCE TRANSACTIONS</h2>
            <p>
              You acknowledge and agree that only an adult
              (a person who has attained the age of majority
              in their local jurisdiction) can enter into
              and complete a transaction involving the
              payment of actual money. All payments must be
              remitted through an account of a payment
              provider or processor approved by Chancellors
              Way Pharmacy and you agree to abide by the
              terms and conditions applicable to that
              payment method. Chancellors Way Pharmacy, or
              its approved third party payment provider or
              processor will automatically charge your
              Payment Method, plus any applicable taxes we
              are required to collect, where you authorize
              us to do so. You are fully liable for all
              charges to your payments account(s). You
              understand and agree that once you authorize
              us or our approved payment provider or
              processor to charge your payment account(s) or
              the associated credit card for a certain
              amount, such amount is not refundable in whole
              or in part and under any circumstances.
              Including, without limitation, upon
              termination or suspension of your account(s)
              for any reason, termination of these terms of
              use or interruption or discontinuation of the
              site, some or all of the content or
              services.In all cases, your order will
              represent your offer to Chancellors Way
              Pharmacy to acquire the product or service
              ordered, which offer will be deemed to have
              been accepted by Chancellors Way Pharmacy when
              your payment account is charged.
            </p>
          </div>
          <div className="text_block">
            <h2>CONTENT OWNERSHIP AND USE</h2>
            <h3>Health-Related Content</h3>
            <p>
              Any health-related Content displayed on the
              Site describes general health care principles
              and is for general guidance only. Such Content
              should not be interpreted as specific
              instructions for individual users, and is not
              intended as a substitute for a consultation
              with your physician, pharmacist or a licensed
              health care professional, and should not be
              used to diagnose or treat a health problem
              without first consulting your physician or a
              licensed health care professional. You should
              discuss any information provided through
              access to the Site and/or Services with a
              physician, pharmacist, nurse or other licensed
              health care professional. You should also
              check product information regarding dosage,
              precautions, and warnings before administering
              or using any device, drug, herb, vitamin or
              supplement referenced in the Site, Content or
              Services. <br />
              In no event will Chancellors Way Pharmacy be
              liable for any decision made or action taken
              in reliance on the Content or other
              information contained on, or accessible
              through, the Site and/or Services. Reliance on
              any information provided by, or otherwise
              appearing on, the Site and/or Services is
              solely at your own risk.{' '}
            </p>
            <h3>
              Trademarks, Logos and Chancellors Way
              Pharmacy’s Branding
            </h3>
            <p>
              The trademarks, logos, signs, symbols, images
              and brands (“Marks”) displayed on the Site are
              the property of Chancellors Way Pharmacy or
              our third party licensors. You are prohibited
              from using any Marks for any purpose
              including, but not limited to, use as metatags
              on other pages or sites on the Internet
              without the written permission of Chancellors
              Way Pharmacy or such third party which may own
              the Marks, which permission may be withheld in
              the sole discretion of the owner of the
              relevant Marks. All Content available on or
              through the Site or Services is protected by
              intellectual property laws. You are prohibited
              from modifying, copying, distributing,
              transmitting, displaying, publishing, selling,
              licensing, creating derivative works or using
              any Content for commercial or public purposes.
            </p>
            <h3>Limited End User License</h3>
            <p>
              Subject to your strict compliance with these
              Terms of Use, Chancellors Way Pharmacy hereby
              grants you a limited, revocable,
              non-exclusive, personal, non-transferable
              license, without the right to sublicense, to
              access and use the (i) Site and (ii) Services
              which you have downloaded, installed,
              purchased or subscribed for, as applicable, on
              a single computer, mobile device or media
              platform (the “License”). You shall not
              acquire any ownership rights in the Content or
              in any of the Services or the Site. Except as
              expressly permitted under these Terms of Use
              you will have no rights to, either directly or
              indirectly, own, use, loan, sell, rent, lease,
              license, sublicense, assign, copy, translate,
              transmit, modify, adapt, enhance, improve,
              create any derivative works from the Site or
              Services, disclose any part or feature of the
              Site or Services that Chancellors Way Pharmacy
              has not publicly disclosed, or to display,
              distribute, publicly perform or any other way
              exploit, the Site, Services or the Content in
              whole or in part. You acknowledge and agree
              that we may modify, suspend or remove any
              Content or features of the Site or Services at
              any time. <br />
              For further certainty, you hereby acknowledge
              and agree that the general health-related data
              and information made available to you under
              the License is Chancellors Way Pharmacy’
              exclusive property and is of essential value
              to Chancellors Way Pharmacy and you shall not
              aggregate and/or republish, in whole or in
              part, any of the general health-related data
              and information.{' '}
            </p>
            <h3>End-User License Term</h3>
            <p>
              The term of your License commences on the date
              of your acceptance of these Terms of Use and
              shall end on the earlier date of your or
              Chancellors Way Pharmacy’ suspension or
              termination of your Account. Your License
              shall terminate immediately, automatically and
              without notice if you attempt to circumvent
              any technical protection measures used in
              connection with the Site or Services or you
              otherwise breach these Terms of Use.
            </p>
          </div>
          <div className="text_block">
            <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
            <h2>LINKED SITES AND THIRD PARTY SERVICES</h2>
            <h3>Linked Sites</h3>
            <p>
              Chancellors Way Pharmacy prohibits caching
              unauthorized hypertext links to the Site and
              the framing of any Content available through
              the Site. Chancellors Way Pharmacy reserves
              the right to disable any unauthorized links or
              frames and specifically disclaims any
              responsibility for the Content available on
              any other Internet sites linked to the Site.
              Access to any Linked Sites is at your own
              risk. You should be aware that Linked Sites
              may contain rules and regulations, privacy
              provisions, confidentiality provisions,
              transmission of personal data provisions, and
              other provisions that differ from those
              provided on the Site. Chancellors Way Pharmacy
              is not responsible for the policies,
              practices, condition and operation of any
              Linked Sites, and expressly disclaims any and
              all responsibility and liability to you if you
              access or use a Linked Site.
            </p>
            <h3>
              Services not Controlled by Chancellors Way
              Pharmacy
            </h3>
            <p>
              Some of the Content or Services may be
              accessible, or give you the ability to use
              such Content or Services, on or through a
              website, service or device not controlled by
              Chancellors Way Pharmacy. For example, you may
              download, install or access the Content or
              Services through a mobile or web-based online
              market place or have the option to use the
              Content or Services online on servers not
              owned or controlled by Chancellors Way
              Pharmacy, or Chancellors Way Pharmacy may make
              the Content or Services available through
              servers that are not owned or controlled by
              Chancellors Way Pharmacy. Chancellors Way
              Pharmacy takes no responsibility for your
              access to or use of the Content or Services
              through or on any third party device or
              service not controlled by Chancellors Way
              Pharmacy and otherwise has no control over how
              such services are offered, administered or
              operated. Any such use of services not
              controlled by Chancellors Way Pharmacy is at
              your own risk and may subject you to
              additional or different terms and conditions
              imposed by the third party that owns and
              controls such services.
            </p>
          </div>
          <div className="text_block">
            <h2>WARRANTIES AND LIMITATION OF LIABILITY</h2>
            <h3>No Warranties</h3>
            <p>
              To the fullest extent permitted by applicable
              laws, all content, products and services on or
              through the site, content or services or
              obtained from a web site to which the site is
              linked (a “linked site”), are provided to you
              “as is” and “as available” without any
              warranty of any kind either express or implied
              including, but not limited to, the implied
              warranties of merchantability and fitness for
              a particular purpose, title, non-infringement,
              security or accuracy. Chancellors Way Pharmacy
              does not endorse and is not responsible for
              the accuracy or reliability of any opinion,
              advice or statement made through the site,
              content, services or a linked site by any
              party other than Chancellors Way pharmacy, or
              the capabilities or reliability of any product
              or service obtained from a linked site. Except
              as required under applicable consumer
              protection law, under no circumstance will
              Chancellors Way Pharmacy be liable for any
              loss or damage caused by a user’s reliance on
              information obtained through the site or a
              linked site, or a user’s reliance on any
              product or service obtained from a linked
              site. It is the responsibility of the user to
              evaluate the accuracy, completeness or
              usefulness of any opinion, advice or other
              content available through the site, or
              obtained from a linked site. Please seek the
              advice of professionals, as appropriate,
              regarding the evaluation of any specific
              opinion, advice, product, service, or other
              content. Some jurisdictions may not allow
              disclaimers of implied warranties and the
              above disclaimers may not apply to you to the
              extent disclaimed.
            </p>
          </div>
          <div className="text_block">
            <h3>
              Limitation of Liability for Use of the Site,
              Content and Linked Sites
            </h3>
            <p>
              The information, content, services, products
              and descriptions of content or services
              published on the site or a linked site, or
              included in the content or services, may
              include inaccuracies or typographical errors,
              and, to the fullest extent permitted by
              applicable laws, Chancellors Way Pharmacy
              specifically disclaims any liability for such
              inaccuracies or errors. changes are
              periodically made to the information on the
              site or linked sites or contained in the
              content or services. Chancellors Way Pharmacy
              may make improvements or changes to the site,
              content or services at any time.
              <br /> You agree that Chancellors Way Pharmacy
              and its officers, directors, employees, or
              agents will not be liable, whether in
              contract, tort, strict liability, equity or
              under any other legal theory, for any personal
              injury, loss, accident or death or any direct,
              indirect, punitive, consequential, incidental
              or special damages (including without
              limitation lost profits, cost of procuring
              substitute service or lost opportunity)
              arising out of or in connection with the use
              of the site, content, services or a linked
              site, or for any delay or inability to use the
              site, content, services or a linked site, even
              if Chancellors Way Pharmacy is made aware of
              the possibility of such injury, loss,
              accident, death or damages. this limitation on
              liability includes, but is not limited to, the
              transmission of any viruses, malware or
              malicious software which may infect a user’s
              equipment or devices, failure of the content
              or services to be compatible with your
              hardware or software, failure of mechanical or
              electronic equipment or communication or
              interconnect lines or fiber, or other
              connectivity problems ( e.g., you cannot
              access your internet service provider),
              unauthorized access, theft, loss of data,
              operator errors, strikes or other labour
              problems or any force majeure event.
              Chancellors Way Pharmacy cannot and does not
              guarantee continuous, uninterrupted, error
              free or secure access to the site, content or
              services. without limiting the foregoing,
              under no circumstances will Chancellors Way
              Pharmacy be liable to you for any amount that
              exceeds the aggregate of all amounts paid by
              you to Chancellors Way Pharmacy. <br />{' '}
              Applicable laws may not allow the limitation
              or exclusion of liability for incidental or
              consequential damages, so the above limitation
              or exclusion may not apply to you. However,
              even if this is the case, in no event shall
              the total liability of Chancellors Way
              Pharmacy for all damages, losses and causes of
              action and costs and expenses (including legal
              fees) whether in contract, tort (including but
              not limited to negligence), equity or under
              any other legal theory, related to your use
              of, or the inability to use, the site, content
              or services exceed the aggregate of all
              amounts paid by you to Chancellors Way
              pharmacy.
            </p>
            <h3>
              Limitation of Liability for Services Arranged
              Through the Site
            </h3>
            <p>
              You agree that Chancellors Way Pharmacy is a
              mere representative for the content providers
              and suppliers of third party goods and
              services available through the site, content
              or services and Chancellors Way Pharmacy does
              not endorse or control any such content
              providers, third party suppliers or such third
              party goods or services. Any and all claims
              regarding any failure, breach, loss or injury
              with respect to the third party goods and
              services offered through the site are limited
              to claims against any and all such content
              providers and third party suppliers. To the
              fullest extent permitted by applicable laws,
              Chancellors Way Pharmacy hereby disclaims any
              liability, whether based on contract, tort,
              strict liability, equity or any other legal
              theory, including without limitation liability
              for any direct, indirect, punitive,
              consequential, incidental or special damages,
              in connection with the goods or services
              provided by any content provider or other
              supplier through the site, content or
              services, including without limitation
              liability for any act, error, omission,
              injury, loss, accident, delay or irregularity
              which may be incurred through the fault,
              negligent or otherwise, of such content
              provider or supplier and you hereby
              irrevocably waive, release, agree not to sue,
              and forever discharge Chancellors Way Pharmacy
              and its directors, officers, employees and
              agents from any liability with respect to the
              same. Without limiting the foregoing, under no
              circumstances will Chancellors Way Pharmacy be
              liable to you for any amount that exceeds the
              aggregate of all amounts paid by you to
              Chancellors Way Pharmacy. <br />
              Applicable laws may not allow the limitation
              or exclusion of liability for incidental or
              consequential damages, so the above limitation
              or exclusion may not apply to you. However,
              even if this is the case, in no event shall
              the total liability of Chancellors Way
              Pharmacy for all damages, losses and causes of
              action and costs and expenses (including legal
              fees) whether in contract, tort (including but
              not limited to negligence), in equity or under
              any other legal cause of action, related to
              your use of, or the inability to use the site,
              content or services exceed the aggregate of
              all amounts paid by you to Chancellors Way
              Pharmacy.{' '}
            </p>
          </div>
          <div className="text_block">
            <h2>INDEMNITY</h2>
            <p>
              You hereby agree to defend, indemnify and hold
              harmless Chancellors Way Pharmacy and its
              directors, officers, employees, agents,
              successors, administrators and assigns (the
              “indemnified parties”) from and against all
              liabilities, claims, actions, causes of
              action, costs and expenses (including legal
              expenses on a full indemnity basis and any
              other fees and expenses incurred for
              investigating or defending any action or
              threatened action, as well as settlement
              costs) which any of the indemnified parties
              may suffer or incur in connection with any
              claim arising out of or resulting from: (i)
              Your or any permitted minor’s violation of any
              of these terms of use, (ii) Your or any
              permitted minor’s use or misuse of the site,
              content or services, including but not limited
              to your or a permitted minor’s personal injury
              or death, (iii) Your or a permitted minor’s
              use or misuse of any linked sites, and (iv)
              your or any permitted minor’s violation of any
              law or third party rights.
              <br /> Without derogating from or excusing
              your obligations under this section we reserve
              the right (at your expense), but are not under
              any obligation, to assume the exclusive
              defense and control of any matter which is
              subject to an indemnification by you even if
              you choose to defend or settle it. you agree
              not to settle any matter that is subject to an
              indemnification by you without first obtaining
              our express approval.
            </p>
          </div>
          <div className="text_block">
            <h2>VIOLATIONS OF TERMS OF USE</h2>
            <p>
              You agree and acknowledge that Chancellors Way
              Pharmacy may, in its sole and absolute
              discretion and without prior notice to you,
              terminate or suspend your access to the Site
              and your use of Chancellors Way Pharmacy’
              products and services, or any portion thereof,
              and cancel your Account if Chancellors Way
              Pharmacy has reason to believe that you have
              failed to strictly comply with these Terms of
              Use or that the use of the Site by you
              violates the rights of any third party or any
              applicable laws. In addition to the foregoing,
              Chancellors Way Pharmacy reserves the right to
              pursue all remedies available at law and in
              equity for violations of these Terms of Use,
              including but not limited to the right to
              block access from a particular Internet
              address to the Site. You agree and acknowledge
              that monetary damages may not be an adequate
              remedy for any violation of these Terms of Use
              by you and, without limiting any of
              Chancellors Way Pharmacy’ other remedies, you
              hereby consent to, and authorize Chancellors
              Way Pharmacy to obtain, an injunction or other
              equitable relief from any court of competent
              jurisdiction. You further authorize
              Chancellors Way Pharmacy to disclose your
              personally identifiable information where
              reasonably necessary in connection with the
              foregoing.
            </p>
          </div>
          <div className="text_block">
            <h2>TERMINATION</h2>
            <p>
              These Terms of Use, as amended from time to
              time by Chancellors Way Pharmacy, shall remain
              effective until terminated by you or
              Chancellors Way Pharmacy. You may terminate
              these Terms of Use by discontinuing use of the
              Site, Content, Services and your associated
              Account(s) and destroying all electronic and
              other copies of all related Content, including
              related documentation. Without limiting any
              other provision of these Terms of Use or the
              remedies available to Chancellors Way
              Pharmacy, Chancellors Way Pharmacy may
              immediately terminate these Terms of Use or
              cancel or suspend your Account(s) and access
              to the Site, Content or Services in its sole
              and absolute discretion and for any reason
              including, without limitation, if you violate
              or otherwise fail to strictly comply with any
              term or provision of these Terms of Use. Upon
              termination of these Terms of Use for any
              reason, you must cease use of your Account(s),
              cease accessing the Site, Content and Services
              and promptly destroy all electronic and other
              copies of the Content, including all related
              documentation.
            </p>
            <h2>DISPUTE RESOLUTION</h2>
            <p>
              You and Chancellors Way Pharmacy agree that
              any dispute, claim or controversy arising out
              of or relating to these Terms of Use, any
              policy or guarantee referred to herein, or the
              breach, termination, enforcement,
              interpretation or the validity thereof, or the
              use of the Site, Content or Services
              (collectively, the “Disputes”) will be settled
              by binding arbitration, except that each party
              retains the right to (i) bring an individual
              action in an applicable small claims court,
              such as the Ontario Provincial Court (Small
              Claims Division) in respect of any dispute
              relating to Chancellors Way Pharmacy Inc., if
              the action is within the jurisdiction of that
              court and (ii) seek injunctive or other
              equitable relief in a court of competent
              jurisdiction to prevent actual or threatened
              infringement, misappropriation or violation of
              a party’s copyrights, trademarks, trade
              secrets, patents or other intellectual
              property rights (the “Excluded Disputes”).
              Without derogating from or excusing your
              obligations under this section, we have the
              right (but not the obligation), in our sole
              discretion, to assume the exclusive defense
              and control of any matter which is subject to
              an Excluded Dispute. The provisions of this
              paragraph will survive any termination of this
              Agreement.
            </p>
            <h2>GENERAL</h2>
            <h3>Governing Law</h3>
            <p>
              These Terms of Use and your legal relationship
              with Chancellors Way Pharmacy shall be
              governed by and construed in accordance with
              the laws of the province where the dispensing
              pharmacy is located, together with the federal
              laws of Canada applicable therein, without
              regard to its conflicts of laws principles.
              These Terms of Use shall not be governed by
              the United Nations Convention on Contracts for
              the Sale of Goods. The controlling language of
              these Terms of Use is English.
            </p>
            <h3>Waiver and Severability</h3>
            <p>
              The failure of Chancellors Way Pharmacy to
              enforce any right or provision of these Terms
              of Use will not constitute a waiver of such
              right or provision. In the event that any
              provision of these Terms of Use is held to be
              invalid or unenforceable by any court of
              competent jurisdiction, that provision shall
              be enforced to the maximum extent permissible
              and the remaining provisions of the Terms of
              Use will remain in full force and effect. All
              remedies of Chancellors Way Pharmacy hereunder
              shall be cumulative.
            </p>
            <h3>Force Majeure</h3>
            <p>
              Chancellors Way Pharmacy will not be liable
              for, or be considered to be in breach of or
              default under these Terms of Use on account
              of, any delay or failure to perform as
              required by these Terms of Use as a result of
              events beyond the reasonable control of
              Chancellors Way Pharmacy, including without
              limiting the foregoing where the delay or
              failure is due to fires or fire related
              hazards, explosions, power outages or
              interruptions, brownouts, internet service
              provider failure or delay, cyber attacks,
              server downtime, loss of servers, earthquakes,
              floods, spills, epidemics, acts of God, war or
              acts of war, acts of terrorism, riots,
              strikes, lockouts or other labour or
              contractor actions, acts of public
              authorities, or delays or defaults caused by
              common carriers, or other similar events which
              cannot reasonably be foreseen or provided
              against.
            </p>
            <h3>Notices</h3>
            <p>
              Any notices or other communications permitted
              or required here under, including but not
              limited to modifications to these Terms of Use
              or any policy or guarantee referred to herein,
              will be in writing and given by Chancellors
              Way Pharmacy (i) via email (in each case to
              the email address provided by you for your
              Account(s)) or (ii) by posting to the Site.
              Notice delivered by email will be deemed to
              have been received on the date on which such
              notice is transmitted.
            </p>
            <h3>Entire Agreement</h3>
            <p>
              These Terms of Use, together with the Privacy
              Policy and any supplemental terms and
              conditions referred to below, constitute the
              entire agreement between the parties
              concerning the subject-matter hereof and
              supersede and replace any and all prior oral
              or written understandings or agreements
              between you and Chancellors Way Pharmacy
              regarding the Site, Content, and the Services.
            </p>
            <h3>Survival</h3>
            <p>
              The provisions of these Terms of Use which
              require or contemplate performance after the
              expiration or termination of these Terms of
              Use shall remain enforceable notwithstanding
              such expiration or termination.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default terms;
