import React, { useEffect } from 'react';
import './CheckoutComplete.scss';
import Banner from '../../components/banner/Banner';
import NavBar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import complete from '../../assets/complete.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Dropdown from '../../components/Navbar/Dropdown';
import { domain } from '../../config';
import { Helmet } from 'react-helmet';
import { publicRequest } from '../../util/ApiRequests';

const CheckoutComplete = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const navigate = useNavigate();

  // check if order complete otherwise will navigate to homepage
  useEffect(() => {
    const isValidOrder = async () => {
      try {
        await publicRequest('get', `/orders/${orderId}`);
      } catch (err) {
        navigate('/');
      }
    };
    isValidOrder();
  }, []);

  return (
    <div className="checkout-complete">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order Complete </title>
        <link rel="canonical" href={`${domain}/order-complete`} />
      </Helmet>
      <Banner />
      <Dropdown />
      <NavBar />
      <div className="checkout-complete-wrapper">
        <div className="checkout-complete-box">
          <img src={complete} alt="complete-icon" />
          <div className="text-div">
            <span className="title">Your Purchase Has Been Submitted!</span>
            <span className="text">
              Congratulations! We are thrilled to inform you that your purchase
              from our shop has been successfully completed.
            </span>
          </div>
          <Link className="toDashboard" to="/my-account/dashboard">
            Go to Dashborad
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutComplete;
