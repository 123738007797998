import axios from 'axios';

export const emailjsSend = async (data, templateId) => {
  try {
    await axios.post(
      'https://api.emailjs.com/api/v1.0/email/send',
      {
        service_id: process.env.REACT_APP_EMAILJS_SERVICEID,
        template_id: templateId,
        user_id: process.env.REACT_APP_EMAILJS_USERID,
        template_params: data,
      }
    );
    return true;
  } catch (err) {
    return false;
  }
};
