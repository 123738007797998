import React, { useRef, useState } from 'react';
import './Navbar.css';
import logo from '../../assets/newLogo.PNG';
import { Link } from 'react-router-dom';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { BsChevronDown } from 'react-icons/bs';
import MiniCart from '../MiniCart/MiniCart';
import icon1 from '../../assets/serv-icon1.svg';
import icon4 from '../../assets/serv-icon4.svg';
import icon3 from '../../assets/ser-icon3.svg';
import icon2 from '../../assets/serv-icon2.svg';
import menuIcons from '../../assets/Mega Menu Icons.svg';
import menuIcons2 from '../../assets/Mega Menu Icons (1).svg';
import mega21 from '../../assets/mega21.svg';
import mega22 from '../../assets/mega22.svg';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import WhishListPopup from '../WhishListPopup/WhishListPopup';
import { openInNewTab } from '../../util/outsideAlert';
import { useMediaQuery } from '@mui/material';

const NavBar = () => {
  const navbar = useRef(null);
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(0);
  const [showWish, setShowWish] = useState(false);
  const cart = useSelector((state) => state.cart.products);
  const pathname = window.location.pathname;
  const matches = useMediaQuery('(min-width:1268px)');

  // const user = useSelector(
  //   (state) => state.user.currentUser
  // );
  const [cartCount, setCartCount] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const getCartCount = () => {
      if (cart?.length > 0) {
        const cartQuantity = cart.reduce(
          (prev, current) => prev + current.quantity,
          0
        );
        setCartCount(cartQuantity);
      } else {
        setCartCount(0);
      }
    };
    getCartCount();
  }, [cart]);

  const handleMega = (current) => {
    if (current === showMenu) {
      setShowMenu(0);
    } else {
      setShowMenu(current);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(0);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(navbar);

  return matches ? (
    <div className="Navbar">
      {showWish && <WhishListPopup setShowWish={setShowWish} />}
      <MiniCart setShow={setShow} show={show} />
      <div className="nav-wrapper">
        <div className="left-box">
          <Link to={'/'} className="logo-img-wrapper-navbar-main">
            <img src={logo} alt="logo" className="logo-img" />
          </Link>
          <div className="links-wrapper" ref={navbar}>
            <div
              className="drop-down-link"
              to="/services"
              onClick={() => handleMega(1)}
            >
              Services
              <BsChevronDown style={{ color: 'black' }} />
              {showMenu === 1 && (
                <div className="drop-menu">
                  <Link to="/services?tab=1" className="menu-box">
                    <div className="icon-div">
                      <img src={icon1} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">
                        Compression Stockings
                      </span>
                      <span className="menuitempara">
                        Comfort & improved circulation with our compression
                        stockings.
                      </span>
                    </div>
                  </Link>
                  <Link to="/services?tab=4" className="menu-box">
                    <div className="icon-div">
                      <img src={icon4} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">Medication Review</span>
                      <span className="menuitempara">
                        Ensuring safe and effective use of your medications.
                      </span>
                    </div>
                  </Link>
                  <Link to="/services?tab=3" className="menu-box">
                    <div className="icon-div">
                      <img src={icon3} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">Flu Shot & Vaccines</span>
                      <span className="menuitempara">
                        Protect yourself with our free seasonal immunizations.
                      </span>
                    </div>
                  </Link>
                  <Link to="/services?tab=2" className="menu-box">
                    <div className="icon-div">
                      <img src={icon2} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">Compliance Packs</span>
                      <span className="menuitempara">
                        Simplify your regimen with our organized medication
                        packs.
                      </span>
                    </div>
                  </Link>
                  <Link to="/minoraliments" className="menu-box">
                    <div className="icon-div">
                      <img src={menuIcons2} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">Minor Aliments</span>
                      <span className="menuitempara">
                        Protect yourself with our free seasonal immunizations.
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    onClick={() =>
                      openInNewTab('https://bookmytest.ca/services/5198246445')
                    }
                    className="menu-box"
                  >
                    <div className="icon-div">
                      <img src={menuIcons} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">Covid-19 Test</span>
                      <span className="menuitempara">
                        Quick and reliable COVID-19 testing.
                      </span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div
              className="drop-down-link"
              to="/compounding"
              onClick={() => handleMega(2)}
            >
              Compounding
              <BsChevronDown style={{ color: 'black' }} />
              {showMenu === 2 && (
                <div className="drop-menu two">
                  <Link to="/CFPatients" className="menu-box">
                    <div className="icon-div">
                      <img src={mega21} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">
                        Compounding for Patients
                      </span>
                      <span className="menuitempara">
                        Customized medications tailored to your unique
                        healthcare needs.
                      </span>
                    </div>
                  </Link>
                  <Link to="/CFPhysicians" className="menu-box">
                    <div className="icon-div">
                      <img src={mega22} alt="img-icon" />
                    </div>
                    <div className="text-div">
                      <span className="menuitemtitle">
                        Compounding for Physicians
                      </span>
                      <span className="menuitempara">
                        Crafting patient-specific medications to enhance
                        therapeutic outcomes.
                      </span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <Link to="/IdealProteinLandingPage">Ideal Protein</Link>
            <Link to="/FillPrescriptionOnlinePage">Prescription</Link>
            <Link
              to="#"
              onClick={() =>
                openInNewTab('https://bookmytest.ca/services/5198246445')
              }
            >
              Covid-19 Test
            </Link>
            <Link to="/About">About Us</Link>
          </div>
        </div>
        <div className="right-box">
          {/* <Link className="side-link" to="/Contact">
            Contact Us
          </Link> */}
          {/* {user ? (
            <Link
              className="side-link"
              to={'/my-account/dashboard'}
            >
              My Account
            </Link>
          ) : (
            <Link className="side-link" to={'/auth/login'}>
              LOGIN
            </Link>
          )} */}
          <div className="bttn-wrapper">
            <button
              aria-label="Wish-List"
              className="cart-bttn count"
              onClick={() => setShowWish(!showWish)}
            >
              <AiOutlineHeart />
            </button>
            <button
              aria-label="Cart"
              className={`cart-bttn ${cartCount > 0 && 'count'}`}
              onClick={() => setShow(true)}
            >
              <HiOutlineShoppingCart />
              {cartCount !== 0 && (
                <span className="cartCount">{cartCount}</span>
              )}
            </button>
            <Link to="/categories" className="shop-bttn">
              Shop
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default NavBar;
