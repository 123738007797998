import React, { useState } from 'react';
import './ForgotPassword.scss';
import mainImage from '../../assets/image 44.svg';
import logo from '../../assets/Black.svg';
import { Link } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { domain } from '../../config';
import { Helmet } from 'react-helmet';

const ForgotPassword = () => {
  const [email, setEmail] = useState();

  return (
    <div className="auth">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password </title>
        <link
          rel="canonical"
          href={`${domain}/auth/forgot-password`}
        />
      </Helmet>
      <div className="wrapper">
        <div className="left-div">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="logo-img"
            />
          </Link>
          <img
            src={mainImage}
            alt="main"
            className="bottom-img"
          />
        </div>
        <div className="right-div">
          <Link to="/" className="home-link">
            <BsChevronLeft />
            back to home page
          </Link>
          <div className="form-div">
            <div className="title-div">
              <p className="title">Forget password?</p>
              <p className="slogan">
                Enter your email address and we will send
                you a link to reset your password.
              </p>
            </div>
            <div className="inputs">
              <div className="input-div">
                <label>Email address</label>
                <input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="submit-div">
                <button
                  className="submit-bttn"
                  disabled={!email ? true : false}
                >
                  reset your password
                </button>
                <Link to="/auth/login">back to login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
